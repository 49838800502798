"use strict";
/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.Api = exports.HttpClient = exports.ContentType = void 0;
require("cross-fetch/polyfill");
const utils_1 = require("./utils");
var ContentType;
(function (ContentType) {
    ContentType["Json"] = "application/json";
    ContentType["FormData"] = "multipart/form-data";
    ContentType["UrlEncoded"] = "application/x-www-form-urlencoded";
    ContentType["Text"] = "text/plain";
})(ContentType = exports.ContentType || (exports.ContentType = {}));
class HttpClient {
    constructor(apiConfig = {}) {
        this.baseUrl = '../';
        this.securityData = null;
        this.abortControllers = new Map();
        this.customFetch = (...fetchParams) => fetch(...fetchParams);
        this.baseApiParams = {
            credentials: 'same-origin',
            headers: {},
            redirect: 'follow',
            referrerPolicy: 'no-referrer'
        };
        this.setSecurityData = (data) => {
            this.securityData = data;
        };
        this.contentFormatters = {
            [ContentType.Json]: (input) => input !== null && (typeof input === 'object' || typeof input === 'string') ? JSON.stringify(input) : input,
            [ContentType.Text]: (input) => (input !== null && typeof input !== 'string' ? JSON.stringify(input) : input),
            [ContentType.FormData]: (input) => Object.keys(input || {}).reduce((formData, key) => {
                const property = input[key];
                formData.append(key, property instanceof Blob
                    ? property
                    : typeof property === 'object' && property !== null
                        ? JSON.stringify(property)
                        : `${property}`);
                return formData;
            }, new FormData()),
            [ContentType.UrlEncoded]: (input) => this.toQueryString(input)
        };
        this.createAbortSignal = (cancelToken) => {
            if (this.abortControllers.has(cancelToken)) {
                const abortController = this.abortControllers.get(cancelToken);
                if (abortController) {
                    return abortController.signal;
                }
                return void 0;
            }
            const abortController = new AbortController();
            this.abortControllers.set(cancelToken, abortController);
            return abortController.signal;
        };
        this.abortRequest = (cancelToken) => {
            const abortController = this.abortControllers.get(cancelToken);
            if (abortController) {
                abortController.abort();
                this.abortControllers.delete(cancelToken);
            }
        };
        this.request = async ({ body, secure, path, type, query, format, baseUrl, cancelToken, ...params }) => {
            const secureParams = ((typeof secure === 'boolean' ? secure : this.baseApiParams.secure) &&
                this.securityWorker &&
                (await this.securityWorker(this.securityData))) ||
                {};
            const requestParams = this.mergeRequestParams(params, secureParams);
            const queryString = query && this.toQueryString(query);
            const payloadFormatter = this.contentFormatters[type || ContentType.Json];
            const responseFormat = format || requestParams.format;
            return this.customFetch(`${baseUrl || this.baseUrl || ''}${path}${queryString ? `?${queryString}` : ''}`, {
                ...requestParams,
                headers: {
                    ...(requestParams.headers || {}),
                    ...(type && type !== ContentType.FormData ? { 'Content-Type': type } : {})
                },
                signal: cancelToken ? this.createAbortSignal(cancelToken) : requestParams.signal,
                body: typeof body === 'undefined' || body === null ? null : payloadFormatter(body)
            }).then(async (response) => {
                const r = response;
                r.data = null;
                r.error = null;
                const data = !responseFormat
                    ? r
                    : await response[responseFormat]()
                        .then((data) => {
                        if (r.ok) {
                            r.data = data;
                        }
                        else {
                            r.error = data;
                        }
                        return r;
                    })
                        .catch((e) => {
                        r.error = e;
                        return r;
                    });
                if (cancelToken) {
                    this.abortControllers.delete(cancelToken);
                }
                return data;
            });
        };
        Object.assign(this, apiConfig);
    }
    encodeQueryParam(key, value) {
        const encodedKey = encodeURIComponent(key);
        return `${encodedKey}=${encodeURIComponent(typeof value === 'number' ? value : `${value}`)}`;
    }
    addQueryParam(query, key) {
        return this.encodeQueryParam(key, query[key]);
    }
    addArrayQueryParam(query, key) {
        const value = query[key];
        return value.map((v) => this.encodeQueryParam(key, v)).join('&');
    }
    toQueryString(rawQuery) {
        const query = rawQuery || {};
        const keys = Object.keys(query).filter((key) => 'undefined' !== typeof query[key]);
        return keys
            .map((key) => (Array.isArray(query[key]) ? this.addArrayQueryParam(query, key) : this.addQueryParam(query, key)))
            .join('&');
    }
    addQueryParams(rawQuery) {
        const queryString = this.toQueryString(rawQuery);
        return queryString ? `?${queryString}` : '';
    }
    mergeRequestParams(params1, params2) {
        return {
            ...this.baseApiParams,
            ...params1,
            ...(params2 || {}),
            headers: {
                ...(this.baseApiParams.headers || {}),
                ...(params1.headers || {}),
                ...((params2 && params2.headers) || {})
            }
        };
    }
}
exports.HttpClient = HttpClient;
/**
 * @title Alephium API
 * @version 3.10.0
 * @baseUrl ../
 */
class Api extends HttpClient {
    constructor() {
        super(...arguments);
        this.wallets = {
            /**
             * No description
             *
             * @tags Wallets
             * @name GetWallets
             * @summary List available wallets
             * @request GET:/wallets
             */
            getWallets: (params = {}) => this.request({
                path: `/wallets`,
                method: 'GET',
                format: 'json',
                ...params
            }).then(utils_1.convertHttpResponse),
            /**
             * No description
             *
             * @tags Wallets
             * @name PutWallets
             * @summary Restore a wallet from your mnemonic
             * @request PUT:/wallets
             */
            putWallets: (data, params = {}) => this.request({
                path: `/wallets`,
                method: 'PUT',
                body: data,
                type: ContentType.Json,
                format: 'json',
                ...params
            }).then(utils_1.convertHttpResponse),
            /**
             * @description A new wallet will be created and respond with a mnemonic. Make sure to keep that mnemonic safely as it will allows you to recover your wallet. Default mnemonic size is 24, (options: 12, 15, 18, 21, 24).
             *
             * @tags Wallets
             * @name PostWallets
             * @summary Create a new wallet
             * @request POST:/wallets
             */
            postWallets: (data, params = {}) => this.request({
                path: `/wallets`,
                method: 'POST',
                body: data,
                type: ContentType.Json,
                format: 'json',
                ...params
            }).then(utils_1.convertHttpResponse),
            /**
             * No description
             *
             * @tags Wallets
             * @name GetWalletsWalletName
             * @summary Get wallet's status
             * @request GET:/wallets/{wallet_name}
             */
            getWalletsWalletName: (walletName, params = {}) => this.request({
                path: `/wallets/${walletName}`,
                method: 'GET',
                format: 'json',
                ...params
            }).then(utils_1.convertHttpResponse),
            /**
             * No description
             *
             * @tags Wallets
             * @name DeleteWalletsWalletName
             * @summary Delete your wallet file (can be recovered with your mnemonic)
             * @request DELETE:/wallets/{wallet_name}
             */
            deleteWalletsWalletName: (walletName, query, params = {}) => this.request({
                path: `/wallets/${walletName}`,
                method: 'DELETE',
                query: query,
                ...params
            }).then(utils_1.convertHttpResponse),
            /**
             * No description
             *
             * @tags Wallets
             * @name PostWalletsWalletNameLock
             * @summary Lock your wallet
             * @request POST:/wallets/{wallet_name}/lock
             */
            postWalletsWalletNameLock: (walletName, params = {}) => this.request({
                path: `/wallets/${walletName}/lock`,
                method: 'POST',
                ...params
            }).then(utils_1.convertHttpResponse),
            /**
             * No description
             *
             * @tags Wallets
             * @name PostWalletsWalletNameUnlock
             * @summary Unlock your wallet
             * @request POST:/wallets/{wallet_name}/unlock
             */
            postWalletsWalletNameUnlock: (walletName, data, params = {}) => this.request({
                path: `/wallets/${walletName}/unlock`,
                method: 'POST',
                body: data,
                type: ContentType.Json,
                ...params
            }).then(utils_1.convertHttpResponse),
            /**
             * No description
             *
             * @tags Wallets
             * @name GetWalletsWalletNameBalances
             * @summary Get your total balance
             * @request GET:/wallets/{wallet_name}/balances
             */
            getWalletsWalletNameBalances: (walletName, params = {}) => this.request({
                path: `/wallets/${walletName}/balances`,
                method: 'GET',
                format: 'json',
                ...params
            }).then(utils_1.convertHttpResponse),
            /**
             * No description
             *
             * @tags Wallets
             * @name PostWalletsWalletNameRevealMnemonic
             * @summary Reveal your mnemonic. !!! use it with caution !!!
             * @request POST:/wallets/{wallet_name}/reveal-mnemonic
             */
            postWalletsWalletNameRevealMnemonic: (walletName, data, params = {}) => this.request({
                path: `/wallets/${walletName}/reveal-mnemonic`,
                method: 'POST',
                body: data,
                type: ContentType.Json,
                format: 'json',
                ...params
            }).then(utils_1.convertHttpResponse),
            /**
             * No description
             *
             * @tags Wallets
             * @name PostWalletsWalletNameTransfer
             * @summary Transfer ALPH from the active address
             * @request POST:/wallets/{wallet_name}/transfer
             */
            postWalletsWalletNameTransfer: (walletName, data, params = {}) => this.request({
                path: `/wallets/${walletName}/transfer`,
                method: 'POST',
                body: data,
                type: ContentType.Json,
                format: 'json',
                ...params
            }).then(utils_1.convertHttpResponse),
            /**
             * No description
             *
             * @tags Wallets
             * @name PostWalletsWalletNameSweepActiveAddress
             * @summary Transfer all unlocked ALPH from the active address to another address
             * @request POST:/wallets/{wallet_name}/sweep-active-address
             */
            postWalletsWalletNameSweepActiveAddress: (walletName, data, params = {}) => this.request({
                path: `/wallets/${walletName}/sweep-active-address`,
                method: 'POST',
                body: data,
                type: ContentType.Json,
                format: 'json',
                ...params
            }).then(utils_1.convertHttpResponse),
            /**
             * No description
             *
             * @tags Wallets
             * @name PostWalletsWalletNameSweepAllAddresses
             * @summary Transfer unlocked ALPH from all addresses (including all mining addresses if applicable) to another address
             * @request POST:/wallets/{wallet_name}/sweep-all-addresses
             */
            postWalletsWalletNameSweepAllAddresses: (walletName, data, params = {}) => this.request({
                path: `/wallets/${walletName}/sweep-all-addresses`,
                method: 'POST',
                body: data,
                type: ContentType.Json,
                format: 'json',
                ...params
            }).then(utils_1.convertHttpResponse),
            /**
             * No description
             *
             * @tags Wallets
             * @name PostWalletsWalletNameSign
             * @summary Sign the given data and return back the signature
             * @request POST:/wallets/{wallet_name}/sign
             */
            postWalletsWalletNameSign: (walletName, data, params = {}) => this.request({
                path: `/wallets/${walletName}/sign`,
                method: 'POST',
                body: data,
                type: ContentType.Json,
                format: 'json',
                ...params
            }).then(utils_1.convertHttpResponse),
            /**
             * No description
             *
             * @tags Wallets
             * @name GetWalletsWalletNameAddresses
             * @summary List all your wallet's addresses
             * @request GET:/wallets/{wallet_name}/addresses
             */
            getWalletsWalletNameAddresses: (walletName, params = {}) => this.request({
                path: `/wallets/${walletName}/addresses`,
                method: 'GET',
                format: 'json',
                ...params
            }).then(utils_1.convertHttpResponse),
            /**
             * No description
             *
             * @tags Wallets
             * @name GetWalletsWalletNameAddressesAddress
             * @summary Get address' info
             * @request GET:/wallets/{wallet_name}/addresses/{address}
             */
            getWalletsWalletNameAddressesAddress: (walletName, address, params = {}) => this.request({
                path: `/wallets/${walletName}/addresses/${address}`,
                method: 'GET',
                format: 'json',
                ...params
            }).then(utils_1.convertHttpResponse),
            /**
             * @description This endpoint can only be called if the wallet was created with the `isMiner = true` flag
             *
             * @tags Miners
             * @name GetWalletsWalletNameMinerAddresses
             * @summary List all miner addresses per group
             * @request GET:/wallets/{wallet_name}/miner-addresses
             */
            getWalletsWalletNameMinerAddresses: (walletName, params = {}) => this.request({
                path: `/wallets/${walletName}/miner-addresses`,
                method: 'GET',
                format: 'json',
                ...params
            }).then(utils_1.convertHttpResponse),
            /**
             * @description Cannot be called from a miner wallet
             *
             * @tags Wallets
             * @name PostWalletsWalletNameDeriveNextAddress
             * @summary Derive your next address
             * @request POST:/wallets/{wallet_name}/derive-next-address
             */
            postWalletsWalletNameDeriveNextAddress: (walletName, query, params = {}) => this.request({
                path: `/wallets/${walletName}/derive-next-address`,
                method: 'POST',
                query: query,
                format: 'json',
                ...params
            }).then(utils_1.convertHttpResponse),
            /**
             * @description Your wallet need to have been created with the miner flag set to true
             *
             * @tags Miners
             * @name PostWalletsWalletNameDeriveNextMinerAddresses
             * @summary Derive your next miner addresses for each group
             * @request POST:/wallets/{wallet_name}/derive-next-miner-addresses
             */
            postWalletsWalletNameDeriveNextMinerAddresses: (walletName, params = {}) => this.request({
                path: `/wallets/${walletName}/derive-next-miner-addresses`,
                method: 'POST',
                format: 'json',
                ...params
            }).then(utils_1.convertHttpResponse),
            /**
             * No description
             *
             * @tags Wallets
             * @name PostWalletsWalletNameChangeActiveAddress
             * @summary Choose the active address
             * @request POST:/wallets/{wallet_name}/change-active-address
             */
            postWalletsWalletNameChangeActiveAddress: (walletName, data, params = {}) => this.request({
                path: `/wallets/${walletName}/change-active-address`,
                method: 'POST',
                body: data,
                type: ContentType.Json,
                ...params
            }).then(utils_1.convertHttpResponse)
        };
        this.infos = {
            /**
             * No description
             *
             * @tags Infos
             * @name GetInfosNode
             * @summary Get info about that node
             * @request GET:/infos/node
             */
            getInfosNode: (params = {}) => this.request({
                path: `/infos/node`,
                method: 'GET',
                format: 'json',
                ...params
            }).then(utils_1.convertHttpResponse),
            /**
             * No description
             *
             * @tags Infos
             * @name GetInfosVersion
             * @summary Get version about that node
             * @request GET:/infos/version
             */
            getInfosVersion: (params = {}) => this.request({
                path: `/infos/version`,
                method: 'GET',
                format: 'json',
                ...params
            }).then(utils_1.convertHttpResponse),
            /**
             * No description
             *
             * @tags Infos
             * @name GetInfosChainParams
             * @summary Get key params about your blockchain
             * @request GET:/infos/chain-params
             */
            getInfosChainParams: (params = {}) => this.request({
                path: `/infos/chain-params`,
                method: 'GET',
                format: 'json',
                ...params
            }).then(utils_1.convertHttpResponse),
            /**
             * No description
             *
             * @tags Infos
             * @name GetInfosSelfClique
             * @summary Get info about your own clique
             * @request GET:/infos/self-clique
             */
            getInfosSelfClique: (params = {}) => this.request({
                path: `/infos/self-clique`,
                method: 'GET',
                format: 'json',
                ...params
            }).then(utils_1.convertHttpResponse),
            /**
             * No description
             *
             * @tags Infos
             * @name GetInfosInterCliquePeerInfo
             * @summary Get infos about the inter cliques
             * @request GET:/infos/inter-clique-peer-info
             */
            getInfosInterCliquePeerInfo: (params = {}) => this.request({
                path: `/infos/inter-clique-peer-info`,
                method: 'GET',
                format: 'json',
                ...params
            }).then(utils_1.convertHttpResponse),
            /**
             * No description
             *
             * @tags Infos
             * @name GetInfosDiscoveredNeighbors
             * @summary Get discovered neighbors
             * @request GET:/infos/discovered-neighbors
             */
            getInfosDiscoveredNeighbors: (params = {}) => this.request({
                path: `/infos/discovered-neighbors`,
                method: 'GET',
                format: 'json',
                ...params
            }).then(utils_1.convertHttpResponse),
            /**
             * No description
             *
             * @tags Infos
             * @name GetInfosMisbehaviors
             * @summary Get the misbehaviors of peers
             * @request GET:/infos/misbehaviors
             */
            getInfosMisbehaviors: (params = {}) => this.request({
                path: `/infos/misbehaviors`,
                method: 'GET',
                format: 'json',
                ...params
            }).then(utils_1.convertHttpResponse),
            /**
             * No description
             *
             * @tags Infos
             * @name PostInfosMisbehaviors
             * @summary Ban/Unban given peers
             * @request POST:/infos/misbehaviors
             */
            postInfosMisbehaviors: (data, params = {}) => this.request({
                path: `/infos/misbehaviors`,
                method: 'POST',
                body: data,
                type: ContentType.Json,
                ...params
            }).then(utils_1.convertHttpResponse),
            /**
             * No description
             *
             * @tags Infos
             * @name GetInfosUnreachable
             * @summary Get the unreachable brokers
             * @request GET:/infos/unreachable
             */
            getInfosUnreachable: (params = {}) => this.request({
                path: `/infos/unreachable`,
                method: 'GET',
                format: 'json',
                ...params
            }).then(utils_1.convertHttpResponse),
            /**
             * No description
             *
             * @tags Infos
             * @name PostInfosDiscovery
             * @summary Set brokers to be unreachable/reachable
             * @request POST:/infos/discovery
             */
            postInfosDiscovery: (data, params = {}) => this.request({
                path: `/infos/discovery`,
                method: 'POST',
                body: data,
                type: ContentType.Json,
                ...params
            }).then(utils_1.convertHttpResponse),
            /**
             * No description
             *
             * @tags Infos
             * @name GetInfosHistoryHashrate
             * @summary Get history average hashrate on the given time interval
             * @request GET:/infos/history-hashrate
             */
            getInfosHistoryHashrate: (query, params = {}) => this.request({
                path: `/infos/history-hashrate`,
                method: 'GET',
                query: query,
                format: 'json',
                ...params
            }).then(utils_1.convertHttpResponse),
            /**
             * No description
             *
             * @tags Infos
             * @name GetInfosCurrentHashrate
             * @summary Get average hashrate from `now - timespan(millis)` to `now`
             * @request GET:/infos/current-hashrate
             */
            getInfosCurrentHashrate: (query, params = {}) => this.request({
                path: `/infos/current-hashrate`,
                method: 'GET',
                query: query,
                format: 'json',
                ...params
            }).then(utils_1.convertHttpResponse),
            /**
             * No description
             *
             * @tags Infos
             * @name GetInfosCurrentDifficulty
             * @summary Get the average difficulty of the latest blocks from all shards
             * @request GET:/infos/current-difficulty
             */
            getInfosCurrentDifficulty: (params = {}) => this.request({
                path: `/infos/current-difficulty`,
                method: 'GET',
                format: 'json',
                ...params
            }).then(utils_1.convertHttpResponse)
        };
        this.blockflow = {
            /**
             * No description
             *
             * @tags Blockflow
             * @name GetBlockflowBlocks
             * @summary List blocks on the given time interval
             * @request GET:/blockflow/blocks
             */
            getBlockflowBlocks: (query, params = {}) => this.request({
                path: `/blockflow/blocks`,
                method: 'GET',
                query: query,
                format: 'json',
                ...params
            }).then(utils_1.convertHttpResponse),
            /**
             * No description
             *
             * @tags Blockflow
             * @name GetBlockflowBlocksWithEvents
             * @summary List blocks with events on the given time interval
             * @request GET:/blockflow/blocks-with-events
             */
            getBlockflowBlocksWithEvents: (query, params = {}) => this.request({
                path: `/blockflow/blocks-with-events`,
                method: 'GET',
                query: query,
                format: 'json',
                ...params
            }).then(utils_1.convertHttpResponse),
            /**
             * No description
             *
             * @tags Blockflow
             * @name GetBlockflowRichBlocks
             * @summary Given a time interval, list blocks containing events and transactions with enriched input information when node indexes are enabled.
             * @request GET:/blockflow/rich-blocks
             */
            getBlockflowRichBlocks: (query, params = {}) => this.request({
                path: `/blockflow/rich-blocks`,
                method: 'GET',
                query: query,
                format: 'json',
                ...params
            }).then(utils_1.convertHttpResponse),
            /**
             * No description
             *
             * @tags Blockflow
             * @name GetBlockflowBlocksBlockHash
             * @summary Get a block with hash
             * @request GET:/blockflow/blocks/{block_hash}
             */
            getBlockflowBlocksBlockHash: (blockHash, params = {}) => this.request({
                path: `/blockflow/blocks/${blockHash}`,
                method: 'GET',
                format: 'json',
                ...params
            }).then(utils_1.convertHttpResponse),
            /**
             * No description
             *
             * @tags Blockflow
             * @name GetBlockflowMainChainBlockByGhostUncleGhostUncleHash
             * @summary Get a mainchain block by ghost uncle hash
             * @request GET:/blockflow/main-chain-block-by-ghost-uncle/{ghost_uncle_hash}
             */
            getBlockflowMainChainBlockByGhostUncleGhostUncleHash: (ghostUncleHash, params = {}) => this.request({
                path: `/blockflow/main-chain-block-by-ghost-uncle/${ghostUncleHash}`,
                method: 'GET',
                format: 'json',
                ...params
            }).then(utils_1.convertHttpResponse),
            /**
             * No description
             *
             * @tags Blockflow
             * @name GetBlockflowBlocksWithEventsBlockHash
             * @summary Get a block and events with hash
             * @request GET:/blockflow/blocks-with-events/{block_hash}
             */
            getBlockflowBlocksWithEventsBlockHash: (blockHash, params = {}) => this.request({
                path: `/blockflow/blocks-with-events/${blockHash}`,
                method: 'GET',
                format: 'json',
                ...params
            }).then(utils_1.convertHttpResponse),
            /**
             * No description
             *
             * @tags Blockflow
             * @name GetBlockflowRichBlocksBlockHash
             * @summary Get a block containing events and transactions with enriched input information when node indexes are enabled.
             * @request GET:/blockflow/rich-blocks/{block_hash}
             */
            getBlockflowRichBlocksBlockHash: (blockHash, params = {}) => this.request({
                path: `/blockflow/rich-blocks/${blockHash}`,
                method: 'GET',
                format: 'json',
                ...params
            }).then(utils_1.convertHttpResponse),
            /**
             * No description
             *
             * @tags Blockflow
             * @name GetBlockflowIsBlockInMainChain
             * @summary Check if the block is in main chain
             * @request GET:/blockflow/is-block-in-main-chain
             */
            getBlockflowIsBlockInMainChain: (query, params = {}) => this.request({
                path: `/blockflow/is-block-in-main-chain`,
                method: 'GET',
                query: query,
                format: 'json',
                ...params
            }).then(utils_1.convertHttpResponse),
            /**
             * No description
             *
             * @tags Blockflow
             * @name GetBlockflowHashes
             * @summary Get all block's hashes at given height for given groups
             * @request GET:/blockflow/hashes
             */
            getBlockflowHashes: (query, params = {}) => this.request({
                path: `/blockflow/hashes`,
                method: 'GET',
                query: query,
                format: 'json',
                ...params
            }).then(utils_1.convertHttpResponse),
            /**
             * No description
             *
             * @tags Blockflow
             * @name GetBlockflowChainInfo
             * @summary Get infos about the chain from the given groups
             * @request GET:/blockflow/chain-info
             */
            getBlockflowChainInfo: (query, params = {}) => this.request({
                path: `/blockflow/chain-info`,
                method: 'GET',
                query: query,
                format: 'json',
                ...params
            }).then(utils_1.convertHttpResponse),
            /**
             * No description
             *
             * @tags Blockflow
             * @name GetBlockflowHeadersBlockHash
             * @summary Get block header
             * @request GET:/blockflow/headers/{block_hash}
             */
            getBlockflowHeadersBlockHash: (blockHash, params = {}) => this.request({
                path: `/blockflow/headers/${blockHash}`,
                method: 'GET',
                format: 'json',
                ...params
            }).then(utils_1.convertHttpResponse),
            /**
             * No description
             *
             * @tags Blockflow
             * @name GetBlockflowRawBlocksBlockHash
             * @summary Get raw block in hex format
             * @request GET:/blockflow/raw-blocks/{block_hash}
             */
            getBlockflowRawBlocksBlockHash: (blockHash, params = {}) => this.request({
                path: `/blockflow/raw-blocks/${blockHash}`,
                method: 'GET',
                format: 'json',
                ...params
            }).then(utils_1.convertHttpResponse)
        };
        this.addresses = {
            /**
             * No description
             *
             * @tags Addresses
             * @name GetAddressesAddressBalance
             * @summary Get the balance of an address
             * @request GET:/addresses/{address}/balance
             */
            getAddressesAddressBalance: (address, query, params = {}) => this.request({
                path: `/addresses/${address}/balance`,
                method: 'GET',
                query: query,
                format: 'json',
                ...params
            }).then(utils_1.convertHttpResponse),
            /**
             * No description
             *
             * @tags Addresses
             * @name GetAddressesAddressUtxos
             * @summary Get the UTXOs of an address
             * @request GET:/addresses/{address}/utxos
             */
            getAddressesAddressUtxos: (address, params = {}) => this.request({
                path: `/addresses/${address}/utxos`,
                method: 'GET',
                format: 'json',
                ...params
            }).then(utils_1.convertHttpResponse),
            /**
             * No description
             *
             * @tags Addresses
             * @name GetAddressesAddressGroup
             * @summary Get the group of an address
             * @request GET:/addresses/{address}/group
             */
            getAddressesAddressGroup: (address, params = {}) => this.request({
                path: `/addresses/${address}/group`,
                method: 'GET',
                format: 'json',
                ...params
            }).then(utils_1.convertHttpResponse)
        };
        this.transactions = {
            /**
             * No description
             *
             * @tags Transactions
             * @name PostTransactionsBuild
             * @summary Build an unsigned transfer transaction to a number of recipients
             * @request POST:/transactions/build
             */
            postTransactionsBuild: (data, params = {}) => this.request({
                path: `/transactions/build`,
                method: 'POST',
                body: data,
                type: ContentType.Json,
                format: 'json',
                ...params
            }).then(utils_1.convertHttpResponse),
            /**
             * No description
             *
             * @tags Transactions
             * @name PostTransactionsBuildTransferFromOneToManyGroups
             * @summary Build unsigned transfer transactions from an address of one group to addresses of many groups. Each target group requires a dedicated transaction or more in case large number of outputs needed to be split.
             * @request POST:/transactions/build-transfer-from-one-to-many-groups
             */
            postTransactionsBuildTransferFromOneToManyGroups: (data, params = {}) => this.request({
                path: `/transactions/build-transfer-from-one-to-many-groups`,
                method: 'POST',
                body: data,
                type: ContentType.Json,
                format: 'json',
                ...params
            }).then(utils_1.convertHttpResponse),
            /**
             * No description
             *
             * @tags Transactions
             * @name PostTransactionsBuildMultiAddresses
             * @summary Build an unsigned transaction with multiple addresses to a number of recipients
             * @request POST:/transactions/build-multi-addresses
             */
            postTransactionsBuildMultiAddresses: (data, params = {}) => this.request({
                path: `/transactions/build-multi-addresses`,
                method: 'POST',
                body: data,
                type: ContentType.Json,
                format: 'json',
                ...params
            }).then(utils_1.convertHttpResponse),
            /**
             * No description
             *
             * @tags Transactions
             * @name PostTransactionsSweepAddressBuild
             * @summary Build unsigned transactions to send all unlocked ALPH and token balances of one address to another address
             * @request POST:/transactions/sweep-address/build
             */
            postTransactionsSweepAddressBuild: (data, params = {}) => this.request({
                path: `/transactions/sweep-address/build`,
                method: 'POST',
                body: data,
                type: ContentType.Json,
                format: 'json',
                ...params
            }).then(utils_1.convertHttpResponse),
            /**
             * No description
             *
             * @tags Transactions
             * @name PostTransactionsSubmit
             * @summary Submit a signed transaction
             * @request POST:/transactions/submit
             */
            postTransactionsSubmit: (data, params = {}) => this.request({
                path: `/transactions/submit`,
                method: 'POST',
                body: data,
                type: ContentType.Json,
                format: 'json',
                ...params
            }).then(utils_1.convertHttpResponse),
            /**
             * No description
             *
             * @tags Transactions
             * @name PostTransactionsDecodeUnsignedTx
             * @summary Decode an unsigned transaction
             * @request POST:/transactions/decode-unsigned-tx
             */
            postTransactionsDecodeUnsignedTx: (data, params = {}) => this.request({
                path: `/transactions/decode-unsigned-tx`,
                method: 'POST',
                body: data,
                type: ContentType.Json,
                format: 'json',
                ...params
            }).then(utils_1.convertHttpResponse),
            /**
             * No description
             *
             * @tags Transactions
             * @name GetTransactionsDetailsTxid
             * @summary Get transaction details
             * @request GET:/transactions/details/{txId}
             */
            getTransactionsDetailsTxid: (txId, query, params = {}) => this.request({
                path: `/transactions/details/${txId}`,
                method: 'GET',
                query: query,
                format: 'json',
                ...params
            }).then(utils_1.convertHttpResponse),
            /**
             * No description
             *
             * @tags Transactions
             * @name GetTransactionsRichDetailsTxid
             * @summary Get transaction with enriched input information when node indexes are enabled.
             * @request GET:/transactions/rich-details/{txId}
             */
            getTransactionsRichDetailsTxid: (txId, query, params = {}) => this.request({
                path: `/transactions/rich-details/${txId}`,
                method: 'GET',
                query: query,
                format: 'json',
                ...params
            }).then(utils_1.convertHttpResponse),
            /**
             * No description
             *
             * @tags Transactions
             * @name GetTransactionsRawTxid
             * @summary Get raw transaction in hex format
             * @request GET:/transactions/raw/{txId}
             */
            getTransactionsRawTxid: (txId, query, params = {}) => this.request({
                path: `/transactions/raw/${txId}`,
                method: 'GET',
                query: query,
                format: 'json',
                ...params
            }).then(utils_1.convertHttpResponse),
            /**
             * No description
             *
             * @tags Transactions
             * @name GetTransactionsStatus
             * @summary Get tx status
             * @request GET:/transactions/status
             */
            getTransactionsStatus: (query, params = {}) => this.request({
                path: `/transactions/status`,
                method: 'GET',
                query: query,
                format: 'json',
                ...params
            }).then(utils_1.convertHttpResponse),
            /**
             * No description
             *
             * @tags Transactions
             * @name GetTransactionsTxIdFromOutputref
             * @summary Get transaction id from transaction output ref
             * @request GET:/transactions/tx-id-from-outputref
             */
            getTransactionsTxIdFromOutputref: (query, params = {}) => this.request({
                path: `/transactions/tx-id-from-outputref`,
                method: 'GET',
                query: query,
                format: 'json',
                ...params
            }).then(utils_1.convertHttpResponse),
            /**
             * No description
             *
             * @tags Transactions
             * @name PostTransactionsBuildChained
             * @summary Build a chain of transactions
             * @request POST:/transactions/build-chained
             */
            postTransactionsBuildChained: (data, params = {}) => this.request({
                path: `/transactions/build-chained`,
                method: 'POST',
                body: data,
                type: ContentType.Json,
                format: 'json',
                ...params
            }).then(utils_1.convertHttpResponse)
        };
        this.mempool = {
            /**
             * No description
             *
             * @tags Mempool
             * @name GetMempoolTransactions
             * @summary List mempool transactions
             * @request GET:/mempool/transactions
             */
            getMempoolTransactions: (params = {}) => this.request({
                path: `/mempool/transactions`,
                method: 'GET',
                format: 'json',
                ...params
            }).then(utils_1.convertHttpResponse),
            /**
             * No description
             *
             * @tags Mempool
             * @name DeleteMempoolTransactions
             * @summary Remove all transactions from mempool
             * @request DELETE:/mempool/transactions
             */
            deleteMempoolTransactions: (params = {}) => this.request({
                path: `/mempool/transactions`,
                method: 'DELETE',
                ...params
            }).then(utils_1.convertHttpResponse),
            /**
             * No description
             *
             * @tags Mempool
             * @name PutMempoolTransactionsRebroadcast
             * @summary Rebroadcase a mempool transaction to the network
             * @request PUT:/mempool/transactions/rebroadcast
             */
            putMempoolTransactionsRebroadcast: (query, params = {}) => this.request({
                path: `/mempool/transactions/rebroadcast`,
                method: 'PUT',
                query: query,
                ...params
            }).then(utils_1.convertHttpResponse),
            /**
             * No description
             *
             * @tags Mempool
             * @name PutMempoolTransactionsValidate
             * @summary Validate all mempool transactions and remove invalid ones
             * @request PUT:/mempool/transactions/validate
             */
            putMempoolTransactionsValidate: (params = {}) => this.request({
                path: `/mempool/transactions/validate`,
                method: 'PUT',
                ...params
            }).then(utils_1.convertHttpResponse)
        };
        this.contracts = {
            /**
             * No description
             *
             * @tags Contracts
             * @name PostContractsCompileScript
             * @summary Compile a script
             * @request POST:/contracts/compile-script
             */
            postContractsCompileScript: (data, params = {}) => this.request({
                path: `/contracts/compile-script`,
                method: 'POST',
                body: data,
                type: ContentType.Json,
                format: 'json',
                ...params
            }).then(utils_1.convertHttpResponse),
            /**
             * No description
             *
             * @tags Contracts
             * @name PostContractsUnsignedTxExecuteScript
             * @summary Build an unsigned script
             * @request POST:/contracts/unsigned-tx/execute-script
             */
            postContractsUnsignedTxExecuteScript: (data, params = {}) => this.request({
                path: `/contracts/unsigned-tx/execute-script`,
                method: 'POST',
                body: data,
                type: ContentType.Json,
                format: 'json',
                ...params
            }).then(utils_1.convertHttpResponse),
            /**
             * No description
             *
             * @tags Contracts
             * @name PostContractsCompileContract
             * @summary Compile a smart contract
             * @request POST:/contracts/compile-contract
             */
            postContractsCompileContract: (data, params = {}) => this.request({
                path: `/contracts/compile-contract`,
                method: 'POST',
                body: data,
                type: ContentType.Json,
                format: 'json',
                ...params
            }).then(utils_1.convertHttpResponse),
            /**
             * No description
             *
             * @tags Contracts
             * @name PostContractsCompileProject
             * @summary Compile a project
             * @request POST:/contracts/compile-project
             */
            postContractsCompileProject: (data, params = {}) => this.request({
                path: `/contracts/compile-project`,
                method: 'POST',
                body: data,
                type: ContentType.Json,
                format: 'json',
                ...params
            }).then(utils_1.convertHttpResponse),
            /**
             * No description
             *
             * @tags Contracts
             * @name PostContractsUnsignedTxDeployContract
             * @summary Build an unsigned contract
             * @request POST:/contracts/unsigned-tx/deploy-contract
             */
            postContractsUnsignedTxDeployContract: (data, params = {}) => this.request({
                path: `/contracts/unsigned-tx/deploy-contract`,
                method: 'POST',
                body: data,
                type: ContentType.Json,
                format: 'json',
                ...params
            }).then(utils_1.convertHttpResponse),
            /**
             * No description
             *
             * @tags Contracts
             * @name GetContractsAddressState
             * @summary Get contract state
             * @request GET:/contracts/{address}/state
             */
            getContractsAddressState: (address, params = {}) => this.request({
                path: `/contracts/${address}/state`,
                method: 'GET',
                format: 'json',
                ...params
            }).then(utils_1.convertHttpResponse),
            /**
             * No description
             *
             * @tags Contracts
             * @name GetContractsCodehashCode
             * @summary Get contract code by code hash
             * @request GET:/contracts/{codeHash}/code
             */
            getContractsCodehashCode: (codeHash, params = {}) => this.request({
                path: `/contracts/${codeHash}/code`,
                method: 'GET',
                format: 'json',
                ...params
            }).then(utils_1.convertHttpResponse),
            /**
             * No description
             *
             * @tags Contracts
             * @name PostContractsTestContract
             * @summary Test contract
             * @request POST:/contracts/test-contract
             */
            postContractsTestContract: (data, params = {}) => this.request({
                path: `/contracts/test-contract`,
                method: 'POST',
                body: data,
                type: ContentType.Json,
                format: 'json',
                ...params
            }).then(utils_1.convertHttpResponse),
            /**
             * No description
             *
             * @tags Contracts
             * @name PostContractsCallContract
             * @summary Call contract
             * @request POST:/contracts/call-contract
             */
            postContractsCallContract: (data, params = {}) => this.request({
                path: `/contracts/call-contract`,
                method: 'POST',
                body: data,
                type: ContentType.Json,
                format: 'json',
                ...params
            }).then(utils_1.convertHttpResponse),
            /**
             * No description
             *
             * @tags Contracts
             * @name PostContractsMulticallContract
             * @summary Multiple call contract
             * @request POST:/contracts/multicall-contract
             */
            postContractsMulticallContract: (data, params = {}) => this.request({
                path: `/contracts/multicall-contract`,
                method: 'POST',
                body: data,
                type: ContentType.Json,
                format: 'json',
                ...params
            }).then(utils_1.convertHttpResponse),
            /**
             * No description
             *
             * @tags Contracts
             * @name GetContractsAddressParent
             * @summary Get parent contract address
             * @request GET:/contracts/{address}/parent
             */
            getContractsAddressParent: (address, params = {}) => this.request({
                path: `/contracts/${address}/parent`,
                method: 'GET',
                format: 'json',
                ...params
            }).then(utils_1.convertHttpResponse),
            /**
             * No description
             *
             * @tags Contracts
             * @name GetContractsAddressSubContracts
             * @summary Get sub-contract addresses
             * @request GET:/contracts/{address}/sub-contracts
             */
            getContractsAddressSubContracts: (address, query, params = {}) => this.request({
                path: `/contracts/${address}/sub-contracts`,
                method: 'GET',
                query: query,
                format: 'json',
                ...params
            }).then(utils_1.convertHttpResponse),
            /**
             * No description
             *
             * @tags Contracts
             * @name GetContractsAddressSubContractsCurrentCount
             * @summary Get current value of the sub-contracts counter for a contract
             * @request GET:/contracts/{address}/sub-contracts/current-count
             */
            getContractsAddressSubContractsCurrentCount: (address, params = {}) => this.request({
                path: `/contracts/${address}/sub-contracts/current-count`,
                method: 'GET',
                format: 'json',
                ...params
            }).then(utils_1.convertHttpResponse),
            /**
             * No description
             *
             * @tags Contracts
             * @name PostContractsCallTxScript
             * @summary Call TxScript
             * @request POST:/contracts/call-tx-script
             */
            postContractsCallTxScript: (data, params = {}) => this.request({
                path: `/contracts/call-tx-script`,
                method: 'POST',
                body: data,
                type: ContentType.Json,
                format: 'json',
                ...params
            }).then(utils_1.convertHttpResponse)
        };
        this.multisig = {
            /**
             * No description
             *
             * @tags Multi-signature
             * @name PostMultisigAddress
             * @summary Create the multisig address and unlock script
             * @request POST:/multisig/address
             */
            postMultisigAddress: (data, params = {}) => this.request({
                path: `/multisig/address`,
                method: 'POST',
                body: data,
                type: ContentType.Json,
                format: 'json',
                ...params
            }).then(utils_1.convertHttpResponse),
            /**
             * No description
             *
             * @tags Multi-signature
             * @name PostMultisigBuild
             * @summary Build a multisig unsigned transaction
             * @request POST:/multisig/build
             */
            postMultisigBuild: (data, params = {}) => this.request({
                path: `/multisig/build`,
                method: 'POST',
                body: data,
                type: ContentType.Json,
                format: 'json',
                ...params
            }).then(utils_1.convertHttpResponse),
            /**
             * No description
             *
             * @tags Multi-signature
             * @name PostMultisigSweep
             * @summary Sweep all unlocked ALPH and token balances of a multisig address to another address
             * @request POST:/multisig/sweep
             */
            postMultisigSweep: (data, params = {}) => this.request({
                path: `/multisig/sweep`,
                method: 'POST',
                body: data,
                type: ContentType.Json,
                format: 'json',
                ...params
            }).then(utils_1.convertHttpResponse),
            /**
             * No description
             *
             * @tags Multi-signature
             * @name PostMultisigSubmit
             * @summary Submit a multi-signed transaction
             * @request POST:/multisig/submit
             */
            postMultisigSubmit: (data, params = {}) => this.request({
                path: `/multisig/submit`,
                method: 'POST',
                body: data,
                type: ContentType.Json,
                format: 'json',
                ...params
            }).then(utils_1.convertHttpResponse)
        };
        this.miners = {
            /**
             * No description
             *
             * @tags Miners
             * @name PostMinersCpuMining
             * @summary Execute an action on CPU miner. !!! for test only !!!
             * @request POST:/miners/cpu-mining
             */
            postMinersCpuMining: (query, params = {}) => this.request({
                path: `/miners/cpu-mining`,
                method: 'POST',
                query: query,
                format: 'json',
                ...params
            }).then(utils_1.convertHttpResponse),
            /**
             * No description
             *
             * @tags Miners
             * @name PostMinersCpuMiningMineOneBlock
             * @summary Mine a block on CPU miner. !!! for test only !!!
             * @request POST:/miners/cpu-mining/mine-one-block
             */
            postMinersCpuMiningMineOneBlock: (query, params = {}) => this.request({
                path: `/miners/cpu-mining/mine-one-block`,
                method: 'POST',
                query: query,
                format: 'json',
                ...params
            }).then(utils_1.convertHttpResponse),
            /**
             * No description
             *
             * @tags Miners
             * @name GetMinersAddresses
             * @summary List miner's addresses
             * @request GET:/miners/addresses
             */
            getMinersAddresses: (params = {}) => this.request({
                path: `/miners/addresses`,
                method: 'GET',
                format: 'json',
                ...params
            }).then(utils_1.convertHttpResponse),
            /**
             * No description
             *
             * @tags Miners
             * @name PutMinersAddresses
             * @summary Update miner's addresses, but better to use user.conf instead
             * @request PUT:/miners/addresses
             */
            putMinersAddresses: (data, params = {}) => this.request({
                path: `/miners/addresses`,
                method: 'PUT',
                body: data,
                type: ContentType.Json,
                ...params
            }).then(utils_1.convertHttpResponse)
        };
        this.events = {
            /**
             * No description
             *
             * @tags Events
             * @name GetEventsContractContractaddress
             * @summary Get events for a contract within a counter range
             * @request GET:/events/contract/{contractAddress}
             */
            getEventsContractContractaddress: (contractAddress, query, params = {}) => this.request({
                path: `/events/contract/${contractAddress}`,
                method: 'GET',
                query: query,
                format: 'json',
                ...params
            }).then(utils_1.convertHttpResponse),
            /**
             * No description
             *
             * @tags Events
             * @name GetEventsContractContractaddressCurrentCount
             * @summary Get current value of the events counter for a contract
             * @request GET:/events/contract/{contractAddress}/current-count
             */
            getEventsContractContractaddressCurrentCount: (contractAddress, params = {}) => this.request({
                path: `/events/contract/${contractAddress}/current-count`,
                method: 'GET',
                format: 'json',
                ...params
            }).then(utils_1.convertHttpResponse),
            /**
             * No description
             *
             * @tags Events
             * @name GetEventsTxIdTxid
             * @summary Get contract events for a transaction
             * @request GET:/events/tx-id/{txId}
             */
            getEventsTxIdTxid: (txId, query, params = {}) => this.request({
                path: `/events/tx-id/${txId}`,
                method: 'GET',
                query: query,
                format: 'json',
                ...params
            }).then(utils_1.convertHttpResponse),
            /**
             * No description
             *
             * @tags Events
             * @name GetEventsBlockHashBlockhash
             * @summary Get contract events for a block
             * @request GET:/events/block-hash/{blockHash}
             */
            getEventsBlockHashBlockhash: (blockHash, query, params = {}) => this.request({
                path: `/events/block-hash/${blockHash}`,
                method: 'GET',
                query: query,
                format: 'json',
                ...params
            }).then(utils_1.convertHttpResponse)
        };
        this.utils = {
            /**
             * No description
             *
             * @tags Utils
             * @name PostUtilsVerifySignature
             * @summary Verify the SecP256K1 signature of some data
             * @request POST:/utils/verify-signature
             */
            postUtilsVerifySignature: (data, params = {}) => this.request({
                path: `/utils/verify-signature`,
                method: 'POST',
                body: data,
                type: ContentType.Json,
                format: 'json',
                ...params
            }).then(utils_1.convertHttpResponse),
            /**
             * No description
             *
             * @tags Utils
             * @name PostUtilsTargetToHashrate
             * @summary Convert a target to hashrate
             * @request POST:/utils/target-to-hashrate
             */
            postUtilsTargetToHashrate: (data, params = {}) => this.request({
                path: `/utils/target-to-hashrate`,
                method: 'POST',
                body: data,
                type: ContentType.Json,
                format: 'json',
                ...params
            }).then(utils_1.convertHttpResponse),
            /**
             * No description
             *
             * @tags Utils
             * @name PutUtilsCheckHashIndexing
             * @summary Check and repair the indexing of block hashes
             * @request PUT:/utils/check-hash-indexing
             */
            putUtilsCheckHashIndexing: (params = {}) => this.request({
                path: `/utils/check-hash-indexing`,
                method: 'PUT',
                ...params
            }).then(utils_1.convertHttpResponse)
        };
    }
}
exports.Api = Api;
