"use strict";
/*
Copyright 2018 - 2022 The Alephium Authors
This file is part of the alephium project.

The library is free software: you can redistribute it and/or modify
it under the terms of the GNU Lesser General Public License as published by
the Free Software Foundation, either version 3 of the License, or
(at your option) any later version.

The library is distributed in the hope that it will be useful,
but WITHOUT ANY WARRANTY; without even the implied warranty of
MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
GNU Lesser General Public License for more details.

You should have received a copy of the GNU Lesser General Public License
along with the library. If not, see <http://www.gnu.org/licenses/>.
*/
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.getContractCodeByCodeHash = exports.getTokenIdFromUnsignedTx = exports.getContractIdFromUnsignedTx = exports.getContractEventsCurrentCount = exports.multicallMethods = exports.signExecuteMethod = exports.callMethod = exports.subscribeContractEvents = exports.subscribeContractEvent = exports.decodeEvent = exports.subscribeContractDestroyedEvent = exports.subscribeContractCreatedEvent = exports.fetchContractState = exports.ContractInstance = exports.getMapItem = exports.RalphMap = exports.printDebugMessagesFromTx = exports.getDebugMessagesFromTx = exports.testMethod = exports.extractMapsFromApiResult = exports.addStdIdToFields = exports.subscribeEventsFromContract = exports.decodeContractDestroyedEvent = exports.decodeContractCreatedEvent = exports.DestroyContractEventAddresses = exports.CreateContractEventAddresses = exports.ExecutableScript = exports.ContractFactory = exports.randomTxId = exports.fromApiEventFields = exports.fromApiArray = exports.getDefaultValue = exports.fromApiFields = exports.Script = exports.Contract = exports.Artifact = exports.Struct = exports.DEFAULT_COMPILER_OPTIONS = exports.DEFAULT_NODE_COMPILER_OPTIONS = exports.StdIdFieldName = void 0;
const fs_1 = require("fs");
const api_1 = require("../api");
const ralph = __importStar(require("./ralph"));
const utils_1 = require("../utils");
const address_1 = require("../address");
const global_1 = require("../global");
const events_1 = require("./events");
const constants_1 = require("../constants");
const blake = __importStar(require("blakejs"));
const debug_1 = require("../debug");
const codec_1 = require("../codec");
const error_1 = require("../error");
const crypto = new utils_1.WebCrypto();
exports.StdIdFieldName = '__stdInterfaceId';
exports.DEFAULT_NODE_COMPILER_OPTIONS = {
    ignoreUnusedConstantsWarnings: false,
    ignoreUnusedVariablesWarnings: false,
    ignoreUnusedFieldsWarnings: false,
    ignoreUnusedPrivateFunctionsWarnings: false,
    ignoreUpdateFieldsCheckWarnings: false,
    ignoreCheckExternalCallerWarnings: false,
    ignoreUnusedFunctionReturnWarnings: false,
    skipAbstractContractCheck: false
};
exports.DEFAULT_COMPILER_OPTIONS = { errorOnWarnings: true, ...exports.DEFAULT_NODE_COMPILER_OPTIONS };
class Struct {
    constructor(name, fieldNames, fieldTypes, isMutable) {
        this.name = name;
        this.fieldNames = fieldNames;
        this.fieldTypes = fieldTypes;
        this.isMutable = isMutable;
    }
    static fromJson(json) {
        if (json.name === null || json.fieldNames === null || json.fieldTypes === null || json.isMutable === null) {
            throw Error('The JSON for struct is incomplete');
        }
        return new Struct(json.name, json.fieldNames, json.fieldTypes, json.isMutable);
    }
    static fromStructSig(sig) {
        return new Struct(sig.name, sig.fieldNames, sig.fieldTypes, sig.isMutable);
    }
    toJson() {
        return {
            name: this.name,
            fieldNames: this.fieldNames,
            fieldTypes: this.fieldTypes,
            isMutable: this.isMutable
        };
    }
}
exports.Struct = Struct;
class Artifact {
    constructor(version, name, functions) {
        this.version = version;
        this.name = name;
        this.functions = functions;
    }
    async isDevnet(signer) {
        if (!signer.nodeProvider) {
            return false;
        }
        const chainParams = await signer.nodeProvider.infos.getInfosChainParams();
        return (0, utils_1.isDevnet)(chainParams.networkId);
    }
}
exports.Artifact = Artifact;
function fromFunctionSig(sig) {
    return {
        name: sig.name,
        paramNames: sig.paramNames,
        paramTypes: sig.paramTypes,
        paramIsMutable: sig.paramIsMutable,
        returnTypes: sig.returnTypes
    };
}
class Contract extends Artifact {
    constructor(version, name, bytecode, bytecodeDebugPatch, codeHash, codeHashDebug, fieldsSig, eventsSig, functions, constants, enums, structs, mapsSig, stdInterfaceId) {
        super(version, name, functions);
        this.bytecode = bytecode;
        this.bytecodeDebugPatch = bytecodeDebugPatch;
        this.codeHash = codeHash;
        this.fieldsSig = fieldsSig;
        this.eventsSig = eventsSig;
        this.constants = constants;
        this.enums = enums;
        this.structs = structs;
        this.mapsSig = mapsSig;
        this.stdInterfaceId = stdInterfaceId;
        this.bytecodeDebug = ralph.buildDebugBytecode(this.bytecode, this.bytecodeDebugPatch);
        this.codeHashDebug = codeHashDebug;
        this.decodedContract = codec_1.contract.contractCodec.decodeContract((0, utils_1.hexToBinUnsafe)(this.bytecode));
        this.bytecodeForTesting = undefined;
        this.decodedTestingContract = undefined;
        this.codeHashForTesting = undefined;
    }
    isInlineFunc(index) {
        if (index >= this.functions.length) {
            throw new Error(`Invalid function index ${index}, function size: ${this.functions.length}`);
        }
        const inlineFuncFromIndex = this.decodedContract.methods.length;
        return index >= inlineFuncFromIndex;
    }
    getByteCodeForTesting() {
        if (this.bytecodeForTesting !== undefined)
            return this.bytecodeForTesting;
        const hasInlineFunction = this.functions.length > this.decodedContract.methods.length;
        if (!hasInlineFunction && this.publicFunctions().length == this.functions.length) {
            this.bytecodeForTesting = this.bytecodeDebug;
            this.codeHashForTesting = this.codeHashDebug;
            return this.bytecodeForTesting;
        }
        const decodedDebugContract = codec_1.contract.contractCodec.decodeContract((0, utils_1.hexToBinUnsafe)(this.bytecodeDebug));
        const methods = decodedDebugContract.methods.map((method) => ({ ...method, isPublic: true }));
        const bytecodeForTesting = codec_1.contract.contractCodec.encodeContract({
            fieldLength: decodedDebugContract.fieldLength,
            methods: methods
        });
        const codeHashForTesting = blake.blake2b(bytecodeForTesting, undefined, 32);
        this.bytecodeForTesting = (0, utils_1.binToHex)(bytecodeForTesting);
        this.codeHashForTesting = (0, utils_1.binToHex)(codeHashForTesting);
        return this.bytecodeForTesting;
    }
    getDecodedTestingContract() {
        if (this.decodedTestingContract !== undefined)
            return this.decodedTestingContract;
        const bytecodeForTesting = (0, utils_1.hexToBinUnsafe)(this.getByteCodeForTesting());
        this.decodedTestingContract = codec_1.contract.contractCodec.decodeContract(bytecodeForTesting);
        return this.decodedTestingContract;
    }
    hasCodeHash(hash) {
        return this.codeHash === hash || this.codeHashDebug === hash || this.codeHashForTesting === hash;
    }
    getDecodedMethod(methodIndex) {
        return this.decodedContract.methods[`${methodIndex}`];
    }
    publicFunctions() {
        return this.functions.filter((_, index) => this.getDecodedMethod(index).isPublic);
    }
    usingPreapprovedAssetsFunctions() {
        return this.functions.filter((_, index) => this.getDecodedMethod(index).usePreapprovedAssets);
    }
    usingAssetsInContractFunctions() {
        return this.functions.filter((_, index) => this.getDecodedMethod(index).useContractAssets);
    }
    isMethodUsePreapprovedAssets(isDevnet, methodIndex) {
        if (!isDevnet || !this.isInlineFunc(methodIndex))
            return this.getDecodedMethod(methodIndex).usePreapprovedAssets;
        const contract = this.getDecodedTestingContract();
        return contract.methods[`${methodIndex}`].usePreapprovedAssets;
    }
    // TODO: safely parse json
    static fromJson(artifact, bytecodeDebugPatch = '', codeHashDebug = '', structs = []) {
        if (artifact.version == null ||
            artifact.name == null ||
            artifact.bytecode == null ||
            artifact.codeHash == null ||
            artifact.fieldsSig == null ||
            artifact.eventsSig == null ||
            artifact.constants == null ||
            artifact.enums == null ||
            artifact.functions == null) {
            throw Error('The artifact JSON for contract is incomplete');
        }
        const contract = new Contract(artifact.version, artifact.name, artifact.bytecode, bytecodeDebugPatch, artifact.codeHash, codeHashDebug ? codeHashDebug : artifact.codeHash, artifact.fieldsSig, artifact.eventsSig, artifact.functions, artifact.constants, artifact.enums, structs, artifact.mapsSig === null ? undefined : artifact.mapsSig, artifact.stdInterfaceId === null ? undefined : artifact.stdInterfaceId);
        return contract;
    }
    static fromCompileResult(result, structs = []) {
        return new Contract(result.version, result.name, result.bytecode, result.bytecodeDebugPatch, result.codeHash, result.codeHashDebug, result.fields, result.events, result.functions.map(fromFunctionSig), result.constants, result.enums, structs, result.maps, result.stdInterfaceId);
    }
    // support both 'code.ral' and 'code.ral.json'
    static async fromArtifactFile(path, bytecodeDebugPatch, codeHashDebug, structs = []) {
        const content = await fs_1.promises.readFile(path);
        const artifact = JSON.parse(content.toString());
        return Contract.fromJson(artifact, bytecodeDebugPatch, codeHashDebug, structs);
    }
    toString() {
        const object = {
            version: this.version,
            name: this.name,
            bytecode: this.bytecode,
            codeHash: this.codeHash,
            fieldsSig: this.fieldsSig,
            eventsSig: this.eventsSig,
            functions: this.functions,
            constants: this.constants,
            enums: this.enums
        };
        if (this.mapsSig !== undefined) {
            object.mapsSig = this.mapsSig;
        }
        if (this.stdInterfaceId !== undefined) {
            object.stdInterfaceId = this.stdInterfaceId;
        }
        return JSON.stringify(object, null, 2);
    }
    getInitialFieldsWithDefaultValues() {
        const fields = this.stdInterfaceId === undefined
            ? this.fieldsSig
            : {
                names: this.fieldsSig.names.slice(0, -1),
                types: this.fieldsSig.types.slice(0, -1),
                isMutable: this.fieldsSig.isMutable.slice(0, -1)
            };
        return getDefaultValue(fields, this.structs);
    }
    toState(fields, asset, address) {
        const addressDef = typeof address !== 'undefined' ? address : Contract.randomAddress();
        return {
            address: addressDef,
            contractId: (0, utils_1.binToHex)((0, address_1.contractIdFromAddress)(addressDef)),
            bytecode: this.bytecode,
            codeHash: this.codeHash,
            fields: fields,
            fieldsSig: this.fieldsSig,
            asset: asset
        };
    }
    // no need to be cryptographically strong random
    static randomAddress() {
        const bytes = new Uint8Array(33);
        crypto.getRandomValues(bytes);
        bytes[0] = 3;
        return utils_1.bs58.encode(bytes);
    }
    printDebugMessages(funcName, messages) {
        if ((0, debug_1.isContractDebugMessageEnabled)() && messages.length != 0) {
            console.log(`Testing ${this.name}.${funcName}:`);
            messages.forEach((m) => printDebugMessage(m));
        }
    }
    toApiFields(fields) {
        if (typeof fields === 'undefined') {
            return [];
        }
        else {
            return toApiFields(fields, this.fieldsSig, this.structs);
        }
    }
    toApiArgs(funcName, args) {
        if (args) {
            const func = this.functions.find((func) => func.name == funcName);
            if (func == null) {
                throw new Error(`Invalid function name: ${funcName}`);
            }
            return toApiArgs(args, func, this.structs);
        }
        else {
            return [];
        }
    }
    getMethodIndex(funcName) {
        return this.functions.findIndex((func) => func.name === funcName);
    }
    toApiContractStates(states) {
        return typeof states != 'undefined' ? states.map((state) => toApiContractState(state, this.structs)) : undefined;
    }
    toApiTestContractParams(funcName, params) {
        const allFields = params.initialFields === undefined
            ? []
            : ralph.flattenFields(params.initialFields, this.fieldsSig.names, this.fieldsSig.types, this.fieldsSig.isMutable, this.structs);
        const immFields = allFields.filter((f) => !f.isMutable).map((f) => (0, api_1.toApiVal)(f.value, f.type));
        const mutFields = allFields.filter((f) => f.isMutable).map((f) => (0, api_1.toApiVal)(f.value, f.type));
        const methodIndex = this.getMethodIndex(funcName);
        return {
            group: params.group,
            blockHash: params.blockHash,
            blockTimeStamp: params.blockTimeStamp,
            txId: params.txId,
            address: params.address,
            callerAddress: params.callerAddress,
            bytecode: this.isInlineFunc(methodIndex) ? this.getByteCodeForTesting() : this.bytecodeDebug,
            initialImmFields: immFields,
            initialMutFields: mutFields,
            initialAsset: typeof params.initialAsset !== 'undefined' ? toApiAsset(params.initialAsset) : undefined,
            methodIndex,
            args: this.toApiArgs(funcName, params.testArgs),
            existingContracts: this.toApiContractStates(params.existingContracts),
            inputAssets: toApiInputAssets(params.inputAssets)
        };
    }
    fromApiContractState(state) {
        return {
            address: state.address,
            contractId: (0, utils_1.binToHex)((0, address_1.contractIdFromAddress)(state.address)),
            bytecode: state.bytecode,
            initialStateHash: state.initialStateHash,
            codeHash: state.codeHash,
            fields: fromApiFields(state.immFields, state.mutFields, this.fieldsSig, this.structs),
            fieldsSig: this.fieldsSig,
            asset: fromApiAsset(state.asset)
        };
    }
    static fromApiContractState(state, getContractByCodeHash) {
        const contract = getContractByCodeHash(state.codeHash);
        return contract.fromApiContractState(state);
    }
    static fromApiEvent(event, codeHash, txId, getContractByCodeHash) {
        let fields;
        let name;
        if (event.eventIndex == Contract.ContractCreatedEventIndex) {
            fields = toContractCreatedEventFields(fromApiEventFields(event.fields, Contract.ContractCreatedEvent, true));
            name = Contract.ContractCreatedEvent.name;
        }
        else if (event.eventIndex == Contract.ContractDestroyedEventIndex) {
            fields = fromApiEventFields(event.fields, Contract.ContractDestroyedEvent, true);
            name = Contract.ContractDestroyedEvent.name;
        }
        else {
            const contract = getContractByCodeHash(codeHash);
            const eventSig = contract.eventsSig[event.eventIndex];
            fields = fromApiEventFields(event.fields, eventSig);
            name = eventSig.name;
        }
        return {
            txId: txId,
            blockHash: event.blockHash,
            contractAddress: event.contractAddress,
            name: name,
            eventIndex: event.eventIndex,
            fields: fields
        };
    }
    fromApiTestContractResult(methodName, result, txId, getContractByCodeHash) {
        const methodIndex = this.functions.findIndex((sig) => sig.name === methodName);
        const returnTypes = this.functions[`${methodIndex}`].returnTypes;
        const rawReturn = fromApiArray(result.returns, returnTypes, this.structs);
        const returns = rawReturn.length === 0 ? null : rawReturn.length === 1 ? rawReturn[0] : rawReturn;
        const addressToCodeHash = new Map();
        addressToCodeHash.set(result.address, result.codeHash);
        result.contracts.forEach((contract) => addressToCodeHash.set(contract.address, contract.codeHash));
        return {
            contractId: (0, utils_1.binToHex)((0, address_1.contractIdFromAddress)(result.address)),
            contractAddress: result.address,
            returns: returns,
            gasUsed: result.gasUsed,
            contracts: result.contracts.map((contract) => Contract.fromApiContractState(contract, getContractByCodeHash)),
            txOutputs: result.txOutputs.map(fromApiOutput),
            events: Contract.fromApiEvents(result.events, addressToCodeHash, txId, getContractByCodeHash),
            debugMessages: result.debugMessages
        };
    }
    async txParamsForDeployment(signer, params) {
        const isDevnet = await this.isDevnet(signer);
        const initialFields = params.initialFields ?? {};
        const bytecode = this.buildByteCodeToDeploy(addStdIdToFields(this, initialFields), isDevnet, params.exposePrivateFunctions ?? false);
        const selectedAccount = await signer.getSelectedAccount();
        const signerParams = {
            signerAddress: selectedAccount.address,
            signerKeyType: selectedAccount.keyType,
            bytecode: bytecode,
            initialAttoAlphAmount: params?.initialAttoAlphAmount,
            issueTokenAmount: params?.issueTokenAmount,
            issueTokenTo: params?.issueTokenTo,
            initialTokenAmounts: params?.initialTokenAmounts,
            gasAmount: params?.gasAmount,
            gasPrice: params?.gasPrice
        };
        return signerParams;
    }
    buildByteCodeToDeploy(initialFields, isDevnet, exposePrivateFunctions = false) {
        if (exposePrivateFunctions && !isDevnet) {
            throw new Error('Cannot expose private functions in non-devnet environment');
        }
        try {
            const bytecode = exposePrivateFunctions && isDevnet
                ? this.getByteCodeForTesting()
                : isDevnet
                    ? this.bytecodeDebug
                    : this.bytecode;
            return ralph.buildContractByteCode(bytecode, initialFields, this.fieldsSig, this.structs);
        }
        catch (error) {
            throw new error_1.TraceableError(`Failed to build bytecode for contract ${this.name}`, error);
        }
    }
    static fromApiEvents(events, addressToCodeHash, txId, getContractByCodeHash) {
        return events.map((event) => {
            const contractAddress = event.contractAddress;
            const codeHash = addressToCodeHash.get(contractAddress);
            if (typeof codeHash !== 'undefined' || event.eventIndex < 0) {
                return Contract.fromApiEvent(event, codeHash, txId, getContractByCodeHash);
            }
            else {
                throw Error(`Cannot find codeHash for the contract address: ${contractAddress}`);
            }
        });
    }
    toApiCallContract(params, groupIndex, contractAddress, methodIndex) {
        const functionSig = this.functions[`${methodIndex}`];
        const args = toApiArgs(params.args ?? {}, functionSig, this.structs);
        return {
            ...params,
            group: groupIndex,
            address: contractAddress,
            methodIndex: methodIndex,
            args: args,
            inputAssets: toApiInputAssets(params.inputAssets)
        };
    }
    fromApiCallContractResult(result, txId, methodIndex, getContractByCodeHash) {
        const returnTypes = this.functions[`${methodIndex}`].returnTypes;
        const callResult = (0, api_1.tryGetCallResult)(result);
        return fromCallResult(callResult, txId, returnTypes, this.structs, getContractByCodeHash);
    }
}
exports.Contract = Contract;
Contract.ContractCreatedEventIndex = -1;
Contract.ContractCreatedEvent = {
    name: 'ContractCreated',
    fieldNames: ['address', 'parentAddress', 'stdInterfaceId'],
    fieldTypes: ['Address', 'Address', 'ByteVec']
};
Contract.ContractDestroyedEventIndex = -2;
Contract.ContractDestroyedEvent = {
    name: 'ContractDestroyed',
    fieldNames: ['address'],
    fieldTypes: ['Address']
};
Contract.DebugEventIndex = -3;
function fromCallResult(callResult, txId, returnTypes, structs, getContractByCodeHash) {
    const rawReturn = fromApiArray(callResult.returns, returnTypes, structs);
    const returns = rawReturn.length === 0 ? null : rawReturn.length === 1 ? rawReturn[0] : rawReturn;
    const addressToCodeHash = new Map();
    callResult.contracts.forEach((contract) => addressToCodeHash.set(contract.address, contract.codeHash));
    return {
        returns: returns,
        gasUsed: callResult.gasUsed,
        contracts: callResult.contracts.map((state) => Contract.fromApiContractState(state, getContractByCodeHash)),
        txInputs: callResult.txInputs,
        txOutputs: callResult.txOutputs.map((output) => fromApiOutput(output)),
        events: Contract.fromApiEvents(callResult.events, addressToCodeHash, txId, getContractByCodeHash),
        debugMessages: callResult.debugMessages
    };
}
class Script extends Artifact {
    constructor(version, name, bytecodeTemplate, bytecodeDebugPatch, fieldsSig, functions, structs) {
        super(version, name, functions);
        this.bytecodeTemplate = bytecodeTemplate;
        this.bytecodeDebugPatch = bytecodeDebugPatch;
        this.fieldsSig = fieldsSig;
        this.structs = structs;
    }
    static fromCompileResult(result, structs = []) {
        return new Script(result.version, result.name, result.bytecodeTemplate, result.bytecodeDebugPatch, result.fields, result.functions.map(fromFunctionSig), structs);
    }
    // TODO: safely parse json
    static fromJson(artifact, bytecodeDebugPatch = '', structs = []) {
        if (artifact.version == null ||
            artifact.name == null ||
            artifact.bytecodeTemplate == null ||
            artifact.fieldsSig == null ||
            artifact.functions == null) {
            throw Error('The artifact JSON for script is incomplete');
        }
        return new Script(artifact.version, artifact.name, artifact.bytecodeTemplate, bytecodeDebugPatch, artifact.fieldsSig, artifact.functions, structs);
    }
    static async fromArtifactFile(path, bytecodeDebugPatch, structs = []) {
        const content = await fs_1.promises.readFile(path);
        const artifact = JSON.parse(content.toString());
        return this.fromJson(artifact, bytecodeDebugPatch, structs);
    }
    toString() {
        const object = {
            version: this.version,
            name: this.name,
            bytecodeTemplate: this.bytecodeTemplate,
            fieldsSig: this.fieldsSig,
            functions: this.functions
        };
        return JSON.stringify(object, null, 2);
    }
    async txParamsForExecution(signer, params) {
        const selectedAccount = await signer.getSelectedAccount();
        const signerParams = {
            signerAddress: selectedAccount.address,
            signerKeyType: selectedAccount.keyType,
            bytecode: this.buildByteCodeToDeploy(params.initialFields ?? {}),
            attoAlphAmount: params.attoAlphAmount,
            tokens: params.tokens,
            gasAmount: params.gasAmount,
            gasPrice: params.gasPrice
        };
        return signerParams;
    }
    buildByteCodeToDeploy(initialFields) {
        try {
            return ralph.buildScriptByteCode(this.bytecodeTemplate, initialFields, this.fieldsSig, this.structs);
        }
        catch (error) {
            throw new error_1.TraceableError(`Failed to build bytecode for script ${this.name}`, error);
        }
    }
}
exports.Script = Script;
function fromApiFields(immFields, mutFields, fieldsSig, structs) {
    let [immIndex, mutIndex] = [0, 0];
    const func = (type, isMutable) => {
        const nodeVal = isMutable ? mutFields[mutIndex++] : immFields[immIndex++];
        return (0, api_1.fromApiPrimitiveVal)(nodeVal, type);
    };
    return fieldsSig.names.reduce((acc, name, index) => {
        const fieldType = fieldsSig.types[`${index}`];
        const isMutable = fieldsSig.isMutable[`${index}`];
        acc[`${name}`] = buildVal(isMutable, fieldType, structs, func);
        return acc;
    }, {});
}
exports.fromApiFields = fromApiFields;
function buildVal(isMutable, type, structs, func) {
    if (type.startsWith('[')) {
        const [baseType, size] = (0, api_1.decodeArrayType)(type);
        return Array.from(Array(size).keys()).map(() => buildVal(isMutable, baseType, structs, func));
    }
    const struct = structs.find((s) => s.name === type);
    if (struct !== undefined) {
        return struct.fieldNames.reduce((acc, name, index) => {
            const fieldType = struct.fieldTypes[`${index}`];
            const isFieldMutable = isMutable && struct.isMutable[`${index}`];
            acc[`${name}`] = buildVal(isFieldMutable, fieldType, structs, func);
            return acc;
        }, {});
    }
    const primitiveType = api_1.PrimitiveTypes.includes(type) ? type : 'ByteVec'; // contract type
    return func(primitiveType, isMutable);
}
function getDefaultValue(fieldsSig, structs) {
    return fieldsSig.names.reduce((acc, name, index) => {
        const type = fieldsSig.types[`${index}`];
        acc[`${name}`] = buildVal(false, type, structs, api_1.getDefaultPrimitiveValue);
        return acc;
    }, {});
}
exports.getDefaultValue = getDefaultValue;
function fromApiVal(iter, type, structs, systemEvent = false) {
    const func = (primitiveType) => {
        const currentValue = iter.next();
        if (currentValue.done)
            throw Error('Not enough vals');
        return (0, api_1.fromApiPrimitiveVal)(currentValue.value, primitiveType, systemEvent);
    };
    return buildVal(false, type, structs, func);
}
function fromApiArray(values, types, structs) {
    const iter = values.values();
    return types.map((type) => fromApiVal(iter, type, structs));
}
exports.fromApiArray = fromApiArray;
function fromApiEventFields(vals, eventSig, systemEvent = false) {
    const iter = vals.values();
    return eventSig.fieldNames.reduce((acc, name, index) => {
        const type = eventSig.fieldTypes[`${index}`];
        // currently event does not support struct type
        acc[`${name}`] = fromApiVal(iter, type, [], systemEvent);
        return acc;
    }, {});
}
exports.fromApiEventFields = fromApiEventFields;
function toApiAsset(asset) {
    return {
        attoAlphAmount: (0, api_1.toApiNumber256)(asset.alphAmount),
        tokens: typeof asset.tokens !== 'undefined' ? asset.tokens.map(api_1.toApiToken) : []
    };
}
function fromApiAsset(asset) {
    return {
        alphAmount: (0, api_1.fromApiNumber256)(asset.attoAlphAmount),
        tokens: (0, api_1.fromApiTokens)(asset.tokens)
    };
}
function toApiContractState(state, structs) {
    const stateFields = state.fields ?? {};
    const fieldsSig = state.fieldsSig;
    const allFields = ralph.flattenFields(stateFields, fieldsSig.names, fieldsSig.types, fieldsSig.isMutable, structs);
    const immFields = allFields.filter((f) => !f.isMutable).map((f) => (0, api_1.toApiVal)(f.value, f.type));
    const mutFields = allFields.filter((f) => f.isMutable).map((f) => (0, api_1.toApiVal)(f.value, f.type));
    return {
        address: state.address,
        bytecode: state.bytecode,
        codeHash: state.codeHash,
        initialStateHash: state.initialStateHash,
        immFields,
        mutFields,
        asset: toApiAsset(state.asset)
    };
}
function toApiFields(fields, fieldsSig, structs) {
    return ralph
        .flattenFields(fields, fieldsSig.names, fieldsSig.types, fieldsSig.isMutable, structs)
        .map((f) => (0, api_1.toApiVal)(f.value, f.type));
}
function toApiArgs(args, funcSig, structs) {
    return ralph
        .flattenFields(args, funcSig.paramNames, funcSig.paramTypes, funcSig.paramIsMutable, structs)
        .map((f) => (0, api_1.toApiVal)(f.value, f.type));
}
function toApiInputAsset(inputAsset) {
    return { address: inputAsset.address, asset: toApiAsset(inputAsset.asset) };
}
function toApiInputAssets(inputAssets) {
    return typeof inputAssets !== 'undefined' ? inputAssets.map(toApiInputAsset) : undefined;
}
function fromApiOutput(output) {
    if (output.type === 'AssetOutput') {
        const asset = output;
        return {
            type: 'AssetOutput',
            address: asset.address,
            alphAmount: (0, api_1.fromApiNumber256)(asset.attoAlphAmount),
            tokens: (0, api_1.fromApiTokens)(asset.tokens),
            lockTime: asset.lockTime,
            message: asset.message
        };
    }
    else if (output.type === 'ContractOutput') {
        const asset = output;
        return {
            type: 'ContractOutput',
            address: asset.address,
            alphAmount: (0, api_1.fromApiNumber256)(asset.attoAlphAmount),
            tokens: (0, api_1.fromApiTokens)(asset.tokens)
        };
    }
    else {
        throw new Error(`Unknown output type: ${output}`);
    }
}
function randomTxId() {
    const bytes = new Uint8Array(32);
    crypto.getRandomValues(bytes);
    return (0, utils_1.binToHex)(bytes);
}
exports.randomTxId = randomTxId;
utils_1.assertType;
class ContractFactory {
    constructor(contract) {
        this.contract = contract;
    }
    async deploy(signer, deployParams) {
        const signerParams = await this.contract.txParamsForDeployment(signer, {
            ...deployParams,
            initialFields: addStdIdToFields(this.contract, deployParams.initialFields)
        });
        const result = await signer.signAndSubmitDeployContractTx(signerParams);
        return {
            ...result,
            contractInstance: this.at(result.contractAddress)
        };
    }
    async deployTemplate(signer) {
        return this.deploy(signer, {
            initialFields: this.contract.getInitialFieldsWithDefaultValues()
        });
    }
    // This is used for testing contract functions
    stateForTest_(initFields, asset, address, maps) {
        const newAsset = {
            alphAmount: asset?.alphAmount ?? constants_1.MINIMAL_CONTRACT_DEPOSIT,
            tokens: asset?.tokens
        };
        const state = this.contract.toState(addStdIdToFields(this.contract, initFields), newAsset, address);
        return {
            ...state,
            bytecode: this.contract.bytecodeDebug,
            codeHash: this.contract.codeHash,
            maps: maps
        };
    }
}
exports.ContractFactory = ContractFactory;
class ExecutableScript {
    constructor(script, getContractByCodeHash) {
        this.script = script;
        this.getContractByCodeHash = getContractByCodeHash;
    }
    async execute(signer, params) {
        const signerParams = await this.script.txParamsForExecution(signer, params);
        return await signer.signAndSubmitExecuteScriptTx(signerParams);
    }
    async call(params) {
        const mainFunc = this.script.functions.find((f) => f.name === 'main');
        if (mainFunc === undefined) {
            throw new Error(`There is no main function in script ${this.script.name}`);
        }
        const bytecode = this.script.buildByteCodeToDeploy(params.initialFields);
        const txId = params.txId ?? randomTxId();
        const provider = (0, global_1.getCurrentNodeProvider)();
        const callResult = await provider.contracts.postContractsCallTxScript({
            ...params,
            group: params.groupIndex ?? 0,
            bytecode: bytecode,
            inputAssets: toApiInputAssets(params.inputAssets)
        });
        const returnTypes = mainFunc.returnTypes;
        const result = fromCallResult(callResult, txId, returnTypes, this.script.structs, this.getContractByCodeHash);
        return result;
    }
}
exports.ExecutableScript = ExecutableScript;
function specialContractAddress(eventIndex, groupIndex) {
    const bytes = new Uint8Array(32).fill(0);
    bytes[30] = eventIndex;
    bytes[31] = groupIndex;
    return (0, address_1.addressFromContractId)((0, utils_1.binToHex)(bytes));
}
exports.CreateContractEventAddresses = Array.from(Array(constants_1.TOTAL_NUMBER_OF_GROUPS).keys()).map((groupIndex) => specialContractAddress(Contract.ContractCreatedEventIndex, groupIndex));
exports.DestroyContractEventAddresses = Array.from(Array(constants_1.TOTAL_NUMBER_OF_GROUPS).keys()).map((groupIndex) => specialContractAddress(Contract.ContractDestroyedEventIndex, groupIndex));
function decodeSystemEvent(event, eventSig, eventIndex) {
    if (event.eventIndex !== eventIndex) {
        throw new Error(`Invalid event index: ${event.eventIndex}, expected: ${eventIndex}`);
    }
    return fromApiEventFields(event.fields, eventSig, true);
}
function toContractCreatedEventFields(fields) {
    const parentAddress = fields['parentAddress'];
    const stdInterfaceId = fields['stdInterfaceId'];
    return {
        address: fields['address'],
        parentAddress: parentAddress === '' ? undefined : parentAddress,
        stdInterfaceIdGuessed: stdInterfaceId === '' ? undefined : stdInterfaceId
    };
}
function decodeContractCreatedEvent(event) {
    const fields = decodeSystemEvent(event, Contract.ContractCreatedEvent, Contract.ContractCreatedEventIndex);
    return {
        blockHash: event.blockHash,
        txId: event.txId,
        eventIndex: event.eventIndex,
        name: Contract.ContractCreatedEvent.name,
        fields: toContractCreatedEventFields(fields)
    };
}
exports.decodeContractCreatedEvent = decodeContractCreatedEvent;
function decodeContractDestroyedEvent(event) {
    const fields = decodeSystemEvent(event, Contract.ContractDestroyedEvent, Contract.ContractDestroyedEventIndex);
    return {
        blockHash: event.blockHash,
        txId: event.txId,
        eventIndex: event.eventIndex,
        name: Contract.ContractDestroyedEvent.name,
        fields: { address: fields['address'] }
    };
}
exports.decodeContractDestroyedEvent = decodeContractDestroyedEvent;
function subscribeEventsFromContract(options, address, eventIndex, decodeFunc, fromCount) {
    const messageCallback = (event) => {
        if (event.eventIndex !== eventIndex) {
            return Promise.resolve();
        }
        return options.messageCallback(decodeFunc(event));
    };
    const errorCallback = (err, subscription) => {
        return options.errorCallback(err, subscription);
    };
    const opt = {
        pollingInterval: options.pollingInterval,
        messageCallback: messageCallback,
        errorCallback: errorCallback,
        onEventCountChanged: options.onEventCountChanged
    };
    return (0, events_1.subscribeToEvents)(opt, address, fromCount);
}
exports.subscribeEventsFromContract = subscribeEventsFromContract;
function addStdIdToFields(contract, fields) {
    const stdInterfaceIdPrefix = '414c5048'; // the hex of 'ALPH'
    return contract.stdInterfaceId === undefined
        ? fields
        : { ...fields, __stdInterfaceId: stdInterfaceIdPrefix + contract.stdInterfaceId };
}
exports.addStdIdToFields = addStdIdToFields;
function calcWrapperContractId(parentContractId, mapIndex, key, keyType, group) {
    const prefix = ralph.encodeMapPrefix(mapIndex);
    const encodedKey = ralph.encodeMapKey(key, keyType);
    const path = (0, utils_1.binToHex)(prefix) + (0, utils_1.binToHex)(encodedKey);
    return (0, address_1.subContractId)(parentContractId, path, group);
}
function genCodeForType(type, structs) {
    const { immFields, mutFields } = ralph.calcFieldSize(type, true, structs);
    const loadImmFieldByIndex = {
        isPublic: true,
        usePreapprovedAssets: false,
        useContractAssets: false,
        usePayToContractOnly: false,
        argsLength: 1,
        localsLength: 1,
        returnLength: 1,
        instrs: [(0, codec_1.LoadLocal)(0), codec_1.LoadImmFieldByIndex]
    };
    const loadMutFieldByIndex = {
        ...loadImmFieldByIndex,
        instrs: [(0, codec_1.LoadLocal)(0), codec_1.LoadMutFieldByIndex]
    };
    const parentContractIdIndex = immFields;
    const storeMutFieldByIndex = {
        ...loadImmFieldByIndex,
        argsLength: 2,
        localsLength: 2,
        returnLength: 0,
        instrs: [
            codec_1.CallerContractId,
            (0, codec_1.LoadImmField)(parentContractIdIndex),
            codec_1.ByteVecEq,
            codec_1.Assert,
            (0, codec_1.LoadLocal)(0),
            (0, codec_1.LoadLocal)(1),
            codec_1.StoreMutFieldByIndex
        ]
    };
    const destroy = {
        isPublic: true,
        usePreapprovedAssets: false,
        useContractAssets: true,
        usePayToContractOnly: false,
        argsLength: 1,
        localsLength: 1,
        returnLength: 0,
        instrs: [codec_1.CallerContractId, (0, codec_1.LoadImmField)(parentContractIdIndex), codec_1.ByteVecEq, codec_1.Assert, (0, codec_1.LoadLocal)(0), codec_1.DestroySelf]
    };
    const c = {
        fieldLength: immFields + mutFields + 1,
        methods: [loadImmFieldByIndex, loadMutFieldByIndex, storeMutFieldByIndex, destroy]
    };
    const bytecode = codec_1.contract.contractCodec.encodeContract(c);
    const codeHash = blake.blake2b(bytecode, undefined, 32);
    return { bytecode: (0, utils_1.binToHex)(bytecode), codeHash: (0, utils_1.binToHex)(codeHash) };
}
function getContractFieldsSig(mapValueType) {
    return {
        names: ['value', 'parentContractId'],
        types: [mapValueType, 'ByteVec'],
        isMutable: [true, false]
    };
}
function mapToExistingContracts(contract, parentContractId, group, map, mapIndex, type) {
    const [keyType, valueType] = ralph.parseMapType(type);
    const generatedContract = genCodeForType(valueType, contract.structs);
    return Array.from(map.entries()).map(([key, value]) => {
        const fields = { value, parentContractId };
        const contractId = calcWrapperContractId(parentContractId, mapIndex, key, keyType, group);
        return {
            ...generatedContract,
            address: (0, address_1.addressFromContractId)(contractId),
            contractId: contractId,
            fieldsSig: getContractFieldsSig(valueType),
            fields,
            asset: { alphAmount: constants_1.ONE_ALPH }
        };
    });
}
function mapsToExistingContracts(contract, parentContractId, group, initialMaps) {
    const mapsSig = contract.mapsSig;
    if (mapsSig === undefined)
        return [];
    const contractStates = [];
    Object.keys(initialMaps).forEach((name) => {
        const index = mapsSig.names.findIndex((n) => n === name);
        if (index === -1)
            throw new Error(`Map var ${name} does not exist in contract ${contract.name}`);
        const mapType = mapsSig.types[`${index}`];
        const states = mapToExistingContracts(contract, parentContractId, group, initialMaps[`${name}`], index, mapType);
        contractStates.push(...states);
    });
    return contractStates;
}
function hasMap(state) {
    return state.maps !== undefined;
}
function getTestExistingContracts(selfContract, selfContractId, group, params, getContractByCodeHash) {
    const selfMaps = params.initialMaps ?? {};
    const selfMapEntries = mapsToExistingContracts(selfContract, selfContractId, group, selfMaps);
    const existingContracts = params.existingContracts ?? [];
    const existingMapEntries = existingContracts.flatMap((contractState) => {
        return hasMap(contractState)
            ? mapsToExistingContracts(getContractByCodeHash(contractState.codeHash), contractState.contractId, group, contractState.maps ?? {})
            : [];
    });
    return existingContracts.concat(selfMapEntries, existingMapEntries);
}
function extractMapsFromApiResult(selfAddress, params, group, apiResult, getContractByCodeHash) {
    const selfMaps = params.initialMaps ?? {};
    const existingContracts = params.existingContracts ?? [];
    const filtered = apiResult.contracts.filter((c) => c.address === selfAddress || existingContracts.find((s) => s.address === c.address) !== undefined);
    const allMaps = [];
    filtered.forEach((state) => {
        const artifact = getContractByCodeHash(state.codeHash);
        if (artifact.mapsSig !== undefined) {
            const originMaps = state.address === selfAddress
                ? selfMaps
                : existingContracts.find((s) => s.address === state.address).maps;
            const maps = existingContractsToMaps(artifact, state.address, group, apiResult, originMaps ?? {});
            allMaps.push({ address: state.address, maps });
        }
    });
    return allMaps;
}
exports.extractMapsFromApiResult = extractMapsFromApiResult;
async function testMethod(factory, methodName, params, getContractByCodeHash) {
    const txId = params?.txId ?? randomTxId();
    const selfContract = factory.contract;
    const selfAddress = params.address ?? (0, address_1.addressFromContractId)((0, utils_1.binToHex)(crypto.getRandomValues(new Uint8Array(32))));
    const selfContractId = (0, utils_1.binToHex)((0, address_1.contractIdFromAddress)(selfAddress));
    const group = params.group ?? 0;
    const existingContracts = getTestExistingContracts(selfContract, selfContractId, group, params, getContractByCodeHash);
    const apiParams = selfContract.toApiTestContractParams(methodName, {
        ...params,
        address: selfAddress,
        txId: txId,
        initialFields: addStdIdToFields(selfContract, params.initialFields ?? {}),
        testArgs: params.testArgs === undefined ? {} : params.testArgs,
        existingContracts
    });
    const apiResult = await (0, global_1.getCurrentNodeProvider)().contracts.postContractsTestContract(apiParams);
    const allMaps = extractMapsFromApiResult(selfAddress, params, group, apiResult, getContractByCodeHash);
    const testResult = selfContract.fromApiTestContractResult(methodName, apiResult, txId, getContractByCodeHash);
    testResult.contracts.forEach((c) => {
        const maps = allMaps.find((v) => v.address === c.address)?.maps;
        if (maps !== undefined)
            c['maps'] = maps;
    });
    selfContract.printDebugMessages(methodName, testResult.debugMessages);
    return {
        ...testResult,
        maps: allMaps.find((v) => v.address === selfAddress)?.maps
    };
}
exports.testMethod = testMethod;
function printDebugMessage(m) {
    console.log(`> Contract @ ${m.contractAddress} - ${m.message}`);
}
async function getDebugMessagesFromTx(txId, provider) {
    if ((0, utils_1.isHexString)(txId) && txId.length === 64) {
        const nodeProvider = provider ?? (0, global_1.getCurrentNodeProvider)();
        const events = await nodeProvider.events.getEventsTxIdTxid(txId);
        return events.events
            .filter((e) => e.eventIndex === Contract.DebugEventIndex)
            .map((e) => {
            if (e.fields.length === 1 && e.fields[0].type === 'ByteVec') {
                return {
                    contractAddress: e.contractAddress,
                    message: (0, utils_1.hexToString)(e.fields[0].value)
                };
            }
            else {
                throw new Error(`Invalid debug log: ${JSON.stringify(e.fields)}`);
            }
        });
    }
    else {
        throw new Error(`Invalid tx id: ${txId}`);
    }
}
exports.getDebugMessagesFromTx = getDebugMessagesFromTx;
async function printDebugMessagesFromTx(txId, provider) {
    const messages = await getDebugMessagesFromTx(txId, provider);
    if (messages.length > 0) {
        messages.forEach((m) => printDebugMessage(m));
    }
}
exports.printDebugMessagesFromTx = printDebugMessagesFromTx;
class RalphMap {
    constructor(parentContract, parentContractId, mapName) {
        this.parentContract = parentContract;
        this.parentContractId = parentContractId;
        this.mapName = mapName;
        this.groupIndex = (0, address_1.groupOfAddress)((0, address_1.addressFromContractId)(parentContractId));
    }
    async get(key) {
        return getMapItem(this.parentContract, this.parentContractId, this.groupIndex, this.mapName, key);
    }
    async contains(key) {
        return this.get(key).then((v) => v !== undefined);
    }
    toJSON() {
        return {
            parentContractId: this.parentContractId,
            mapName: this.mapName,
            groupIndex: this.groupIndex
        };
    }
}
exports.RalphMap = RalphMap;
async function getMapItem(parentContract, parentContractId, groupIndex, mapName, key) {
    const index = parentContract.mapsSig?.names.findIndex((name) => name === mapName);
    const mapType = index === undefined ? undefined : parentContract.mapsSig?.types[`${index}`];
    if (mapType === undefined) {
        throw new Error(`Map ${mapName} does not exist in contract ${parentContract.name}`);
    }
    const [keyType, valueType] = ralph.parseMapType(mapType);
    const mapItemContractId = calcWrapperContractId(parentContractId, index, key, keyType, groupIndex);
    const mapItemAddress = (0, address_1.addressFromContractId)(mapItemContractId);
    try {
        const state = await (0, global_1.getCurrentNodeProvider)().contracts.getContractsAddressState(mapItemAddress);
        const fieldsSig = getContractFieldsSig(valueType);
        const fields = fromApiFields(state.immFields, state.mutFields, fieldsSig, parentContract.structs);
        return fields['value'];
    }
    catch (error) {
        if (error instanceof Error && error.message.includes('KeyNotFound')) {
            // the map item contract does not exist
            return undefined;
        }
        throw new error_1.TraceableError(`Failed to get value from map ${mapName}, key: ${key}, parent contract id: ${parentContractId}`, error);
    }
}
exports.getMapItem = getMapItem;
function buildMapInfo(contract, fields) {
    const mapsSig = contract.mapsSig;
    if (mapsSig === undefined)
        return [];
    return mapsSig.names.map((name, index) => {
        const mapType = mapsSig.types[`${index}`];
        const value = (fields[`${name}`] ?? new Map());
        const [keyType, valueType] = ralph.parseMapType(mapType);
        return { name, value, keyType, valueType, index };
    });
}
function extractFromEventLog(contract, result, allMaps, address, group) {
    const parentContractId = (0, utils_1.binToHex)((0, address_1.contractIdFromAddress)(address));
    const newInserted = [];
    result.debugMessages.forEach((message) => {
        if (message.contractAddress !== address)
            return;
        const decoded = ralph.tryDecodeMapDebugLog(message.message);
        if (decoded === undefined)
            return;
        const map = allMaps[`${decoded.mapIndex}`];
        const decodedKey = ralph.decodePrimitive(decoded.encodedKey, map.keyType);
        const contractId = (0, address_1.subContractId)(parentContractId, decoded.path, group);
        if (!decoded.isInsert) {
            map.value.delete(decodedKey);
            return;
        }
        const state = result.contracts.find((s) => s.address === (0, address_1.addressFromContractId)(contractId));
        if (state === undefined) {
            throw new Error(`Cannot find contract state for map value, map field: ${map.name}, value type: ${map.valueType}`);
        }
        newInserted.push(state.address);
        const fieldsSig = getContractFieldsSig(map.valueType);
        const fields = fromApiFields(state.immFields, state.mutFields, fieldsSig, contract.structs);
        map.value.set(decodedKey, fields['value']);
    });
    return newInserted;
}
function updateMaps(contract, result, allMaps, address, group) {
    const parentContractId = (0, utils_1.binToHex)((0, address_1.contractIdFromAddress)(address));
    const updated = [];
    allMaps.forEach((map) => {
        Array.from(map.value.keys()).forEach((key) => {
            const contractId = calcWrapperContractId(parentContractId, map.index, key, map.keyType, group);
            const updatedState = result.contracts.find((s) => s.address === (0, address_1.addressFromContractId)(contractId));
            if (updatedState === undefined)
                return;
            updated.push(updatedState.address);
            const fieldsSig = getContractFieldsSig(map.valueType);
            const fields = fromApiFields(updatedState.immFields, updatedState.mutFields, fieldsSig, contract.structs);
            map.value.set(key, fields['value']);
        });
    });
    return updated;
}
function existingContractsToMaps(contract, address, group, result, maps) {
    const allMaps = buildMapInfo(contract, maps);
    const updated = updateMaps(contract, result, allMaps, address, group);
    const newInserted = extractFromEventLog(contract, result, allMaps, address, group);
    const mapEntries = updated.concat(newInserted);
    const remainContracts = result.contracts.filter((c) => mapEntries.find((addr) => c.address === addr) === undefined);
    result.contracts = remainContracts;
    return allMaps.reduce((acc, map) => {
        acc[`${map.name}`] = map.value;
        return acc;
    }, {});
}
class ContractInstance {
    constructor(address) {
        this.address = address;
        this.contractId = (0, utils_1.binToHex)((0, address_1.contractIdFromAddress)(address));
        this.groupIndex = (0, address_1.groupOfAddress)(address);
    }
}
exports.ContractInstance = ContractInstance;
async function fetchContractState(contract, instance) {
    const contractState = await (0, global_1.getCurrentNodeProvider)().contracts.getContractsAddressState(instance.address);
    const state = contract.contract.fromApiContractState(contractState);
    return {
        ...state,
        fields: state.fields
    };
}
exports.fetchContractState = fetchContractState;
function checkGroupIndex(groupIndex) {
    if (groupIndex < 0 || groupIndex >= constants_1.TOTAL_NUMBER_OF_GROUPS) {
        throw new Error(`Invalid group index ${groupIndex}, expected a value within the range [0, ${constants_1.TOTAL_NUMBER_OF_GROUPS})`);
    }
}
function subscribeContractCreatedEvent(options, fromGroup, fromCount) {
    checkGroupIndex(fromGroup);
    const contractAddress = exports.CreateContractEventAddresses[`${fromGroup}`];
    return subscribeEventsFromContract(options, contractAddress, Contract.ContractCreatedEventIndex, (event) => {
        return {
            ...decodeContractCreatedEvent(event),
            contractAddress: contractAddress
        };
    }, fromCount);
}
exports.subscribeContractCreatedEvent = subscribeContractCreatedEvent;
function subscribeContractDestroyedEvent(options, fromGroup, fromCount) {
    checkGroupIndex(fromGroup);
    const contractAddress = exports.DestroyContractEventAddresses[`${fromGroup}`];
    return subscribeEventsFromContract(options, contractAddress, Contract.ContractDestroyedEventIndex, (event) => {
        return {
            ...decodeContractDestroyedEvent(event),
            contractAddress: contractAddress
        };
    }, fromCount);
}
exports.subscribeContractDestroyedEvent = subscribeContractDestroyedEvent;
function decodeEvent(contract, instance, event, targetEventIndex) {
    if (event.eventIndex !== targetEventIndex &&
        !(targetEventIndex >= 0 && targetEventIndex < contract.eventsSig.length)) {
        throw new Error('Invalid event index: ' + event.eventIndex + ', expected: ' + targetEventIndex);
    }
    const eventSig = contract.eventsSig[`${targetEventIndex}`];
    const fields = fromApiEventFields(event.fields, eventSig);
    return {
        contractAddress: instance.address,
        blockHash: event.blockHash,
        txId: event.txId,
        eventIndex: event.eventIndex,
        name: eventSig.name,
        fields: fields
    };
}
exports.decodeEvent = decodeEvent;
function subscribeContractEvent(contract, instance, options, eventName, fromCount) {
    const eventIndex = contract.eventsSig.findIndex((sig) => sig.name === eventName);
    return subscribeEventsFromContract(options, instance.address, eventIndex, (event) => decodeEvent(contract, instance, event, eventIndex), fromCount);
}
exports.subscribeContractEvent = subscribeContractEvent;
function subscribeContractEvents(contract, instance, options, fromCount) {
    const messageCallback = (event) => {
        return options.messageCallback({
            ...decodeEvent(contract, instance, event, event.eventIndex),
            contractAddress: instance.address
        });
    };
    const errorCallback = (err, subscription) => {
        return options.errorCallback(err, subscription);
    };
    const opt = {
        pollingInterval: options.pollingInterval,
        messageCallback: messageCallback,
        errorCallback: errorCallback,
        onEventCountChanged: options.onEventCountChanged
    };
    return (0, events_1.subscribeToEvents)(opt, instance.address, fromCount);
}
exports.subscribeContractEvents = subscribeContractEvents;
async function callMethod(contract, instance, methodName, params, getContractByCodeHash) {
    const methodIndex = contract.contract.getMethodIndex(methodName);
    const txId = params?.txId ?? randomTxId();
    const callParams = contract.contract.toApiCallContract({ ...params, txId: txId, args: params.args === undefined ? {} : params.args }, instance.groupIndex, instance.address, methodIndex);
    const result = await (0, global_1.getCurrentNodeProvider)().contracts.postContractsCallContract(callParams);
    const callResult = contract.contract.fromApiCallContractResult(result, txId, methodIndex, getContractByCodeHash);
    contract.contract.printDebugMessages(methodName, callResult.debugMessages);
    return callResult;
}
exports.callMethod = callMethod;
async function signExecuteMethod(contract, instance, methodName, params) {
    const methodIndex = contract.contract.getMethodIndex(methodName);
    const functionSig = contract.contract.functions[methodIndex];
    const isDevnet = await contract.contract.isDevnet(params.signer);
    const methodUsePreapprovedAssets = contract.contract.isMethodUsePreapprovedAssets(isDevnet, methodIndex);
    const bytecodeTemplate = getBytecodeTemplate(methodIndex, methodUsePreapprovedAssets, functionSig, contract.contract.structs, params.attoAlphAmount, params.tokens);
    const fieldsSig = toFieldsSig(contract.contract.name, functionSig);
    const bytecode = ralph.buildScriptByteCode(bytecodeTemplate, { __contract__: instance.contractId, ...params.args }, fieldsSig, contract.contract.structs);
    const signer = params.signer;
    const selectedAccount = await signer.getSelectedAccount();
    const signerParams = {
        signerAddress: selectedAccount.address,
        signerKeyType: selectedAccount.keyType,
        bytecode: bytecode,
        attoAlphAmount: params.attoAlphAmount,
        tokens: params.tokens,
        gasAmount: params.gasAmount,
        gasPrice: params.gasPrice
    };
    const result = await signer.signAndSubmitExecuteScriptTx(signerParams);
    if ((0, debug_1.isContractDebugMessageEnabled)() && isDevnet) {
        await printDebugMessagesFromTx(result.txId, signer.nodeProvider);
    }
    return result;
}
exports.signExecuteMethod = signExecuteMethod;
function getBytecodeTemplate(methodIndex, methodUsePreapprovedAssets, functionSig, structs, attoAlphAmount, tokens) {
    // For the default TxScript main function
    const numberOfMethods = '01';
    const isPublic = '01';
    const scriptUseApprovedAssets = attoAlphAmount !== undefined || tokens !== undefined;
    const modifier = scriptUseApprovedAssets ? '03' : '00';
    const argsLength = '00';
    const returnsLength = '00';
    if (methodUsePreapprovedAssets && !scriptUseApprovedAssets) {
        throw new Error('The contract call requires preapproved assets but none are provided');
    }
    const [templateVarStoreLocalInstrs, templateVarsLength] = getTemplateVarStoreLocalInstrs(functionSig, structs);
    const approveAlphInstrs = getApproveAlphInstrs(methodUsePreapprovedAssets ? attoAlphAmount : undefined);
    const approveTokensInstrs = getApproveTokensInstrs(methodUsePreapprovedAssets ? tokens : undefined);
    const callerInstrs = getCallAddressInstrs(approveAlphInstrs.length / 2 + approveTokensInstrs.length / 3);
    // First template var is the contract
    const functionArgsNum = encodeU256Const(BigInt(templateVarsLength - 1));
    const localsLength = encodeI32(templateVarStoreLocalInstrs.length / 2);
    const templateVarLoadLocalInstrs = getTemplateVarLoadLocalInstrs(functionSig, structs);
    const functionReturnTypesLength = functionSig.returnTypes.reduce((acc, returnType) => acc + ralph.typeLength(returnType, structs), 0);
    const functionReturnPopInstrs = encodeInstr(codec_1.Pop).repeat(functionReturnTypesLength);
    const functionReturnNum = encodeU256Const(BigInt(functionReturnTypesLength));
    const contractTemplateVar = '{0}'; // always the 1st argument
    const externalCallInstr = encodeInstr((0, codec_1.CallExternal)(methodIndex));
    const numberOfInstrs = encodeI32(callerInstrs.length +
        approveAlphInstrs.length +
        approveTokensInstrs.length +
        templateVarStoreLocalInstrs.length +
        templateVarLoadLocalInstrs.length +
        functionReturnTypesLength +
        4 // functionArgsNum, functionReturnNum, contractTemplate, externalCallInstr
    );
    return (numberOfMethods +
        isPublic +
        modifier +
        argsLength +
        localsLength +
        returnsLength +
        numberOfInstrs +
        callerInstrs.join('') +
        approveAlphInstrs.join('') +
        approveTokensInstrs.join('') +
        templateVarStoreLocalInstrs.join('') +
        templateVarLoadLocalInstrs.join('') +
        functionArgsNum +
        functionReturnNum +
        contractTemplateVar +
        externalCallInstr +
        functionReturnPopInstrs);
}
function getApproveAlphInstrs(attoAlphAmount) {
    const approveAlphInstrs = [];
    if (attoAlphAmount) {
        const approvedAttoAlphAmount = encodeU256Const(BigInt(attoAlphAmount));
        approveAlphInstrs.push(approvedAttoAlphAmount);
        approveAlphInstrs.push(encodeInstr(codec_1.ApproveAlph));
    }
    return approveAlphInstrs;
}
function getApproveTokensInstrs(tokens) {
    const approveTokensInstrs = [];
    if (tokens) {
        tokens.forEach((token) => {
            const tokenIdBin = (0, utils_1.hexToBinUnsafe)(token.id);
            approveTokensInstrs.push(encodeInstr((0, codec_1.BytesConst)(tokenIdBin)));
            approveTokensInstrs.push(encodeU256Const(BigInt(token.amount)));
            approveTokensInstrs.push(encodeInstr(codec_1.ApproveToken));
        });
    }
    return approveTokensInstrs;
}
function getCallAddressInstrs(approveAssetsNum) {
    const callerInstrs = [];
    if (approveAssetsNum > 0) {
        callerInstrs.push(encodeInstr(codec_1.CallerAddress));
        const dup = encodeInstr(codec_1.Dup);
        if (approveAssetsNum > 1) {
            callerInstrs.push(...new Array(approveAssetsNum - 1).fill(dup));
        }
    }
    return callerInstrs;
}
function getTemplateVarStoreLocalInstrs(functionSig, structs) {
    let templateVarIndex = 1; // Start from 1 since first one is always the contract id
    let localsLength = 0;
    const templateVarStoreInstrs = [];
    functionSig.paramTypes.forEach((paramType) => {
        const fieldsLength = ralph.typeLength(paramType, structs);
        if (fieldsLength > 1) {
            for (let i = 0; i < fieldsLength; i++) {
                templateVarStoreInstrs.push(`{${templateVarIndex + i}}`);
            }
            for (let i = 0; i < fieldsLength; i++) {
                templateVarStoreInstrs.push(encodeStoreLocalInstr(localsLength + (fieldsLength - i - 1)));
            }
            localsLength = localsLength + fieldsLength;
        }
        templateVarIndex = templateVarIndex + fieldsLength;
    });
    return [templateVarStoreInstrs, templateVarIndex];
}
function getTemplateVarLoadLocalInstrs(functionSig, structs) {
    let templateVarIndex = 1;
    let loadIndex = 0;
    const templateVarLoadInstrs = [];
    functionSig.paramTypes.forEach((paramType) => {
        const fieldsLength = ralph.typeLength(paramType, structs);
        if (fieldsLength === 1) {
            templateVarLoadInstrs.push(`{${templateVarIndex}}`);
        }
        if (fieldsLength > 1) {
            for (let i = 0; i < fieldsLength; i++) {
                templateVarLoadInstrs.push(encodeLoadLocalInstr(loadIndex + i));
            }
            loadIndex = loadIndex + fieldsLength;
        }
        templateVarIndex = templateVarIndex + fieldsLength;
    });
    return templateVarLoadInstrs;
}
function encodeStoreLocalInstr(index) {
    if (index < 0 || index > 0xff) {
        throw new Error(`StoreLocal index ${index} must be between 0 and 255 inclusive`);
    }
    return encodeInstr((0, codec_1.StoreLocal)(index));
}
function encodeLoadLocalInstr(index) {
    if (index < 0 || index > 0xff) {
        throw new Error(`LoadLocal index ${index} must be between 0 and 255 inclusive`);
    }
    return encodeInstr((0, codec_1.LoadLocal)(index));
}
function encodeI32(value) {
    return (0, utils_1.binToHex)(codec_1.i32Codec.encode(value));
}
function encodeU256Const(value) {
    if (value < 0) {
        throw new Error(`value ${value} must be non-negative`);
    }
    if (value < 6) {
        return (BigInt(0x0c) + value).toString(16).padStart(2, '0');
    }
    else {
        return encodeInstr((0, codec_1.U256Const)(value));
    }
}
function encodeInstr(instr) {
    return (0, utils_1.binToHex)(codec_1.instrCodec.encode(instr));
}
function toFieldsSig(contractName, functionSig) {
    return {
        names: ['__contract__'].concat(functionSig.paramNames),
        types: [contractName].concat(functionSig.paramTypes),
        isMutable: [false].concat(functionSig.paramIsMutable)
    };
}
async function multicallMethods(contract, instance, _callss, getContractByCodeHash) {
    const callss = Array.isArray(_callss) ? _callss : [_callss];
    const callEntries = callss.map((calls) => Object.entries(calls));
    const callsParams = callEntries.map((entries) => {
        return entries.map((entry) => {
            const [methodName, params] = entry;
            const methodIndex = contract.contract.getMethodIndex(methodName);
            const txId = params?.txId ?? randomTxId();
            return contract.contract.toApiCallContract({ ...params, txId: txId, args: params.args === undefined ? {} : params.args }, instance.groupIndex, instance.address, methodIndex);
        });
    });
    const result = await (0, global_1.getCurrentNodeProvider)().contracts.postContractsMulticallContract({ calls: callsParams.flat() });
    let callResultIndex = 0;
    const results = callsParams.map((calls, index0) => {
        const callsResult = {};
        const entries = callEntries[`${index0}`];
        calls.forEach((call, index1) => {
            const methodIndex = call.methodIndex;
            const callResult = result.results[`${callResultIndex}`];
            const methodName = entries[`${index1}`][`0`];
            callsResult[`${methodName}`] = contract.contract.fromApiCallContractResult(callResult, call.txId, methodIndex, getContractByCodeHash);
            callResultIndex += 1;
        });
        return callsResult;
    });
    return Array.isArray(_callss) ? results : results[0];
}
exports.multicallMethods = multicallMethods;
async function getContractEventsCurrentCount(contractAddress) {
    return (0, global_1.getCurrentNodeProvider)()
        .events.getEventsContractContractaddressCurrentCount(contractAddress)
        .catch((error) => {
        if (error instanceof Error && error.message.includes(`${contractAddress} not found`)) {
            return 0;
        }
        throw new error_1.TraceableError(`Failed to get the event count for the contract ${contractAddress}`, error);
    });
}
exports.getContractEventsCurrentCount = getContractEventsCurrentCount;
// This function only works in the simple case where a single non-subcontract is created in the tx
const getContractIdFromUnsignedTx = async (nodeProvider, unsignedTx) => {
    const result = await nodeProvider.transactions.postTransactionsDecodeUnsignedTx({ unsignedTx });
    const outputIndex = result.unsignedTx.fixedOutputs.length;
    const hex = result.unsignedTx.txId + outputIndex.toString(16).padStart(8, '0');
    const hashHex = (0, utils_1.binToHex)(blake.blake2b((0, utils_1.hexToBinUnsafe)(hex), undefined, 32));
    return hashHex.slice(0, 62) + result.fromGroup.toString(16).padStart(2, '0');
};
exports.getContractIdFromUnsignedTx = getContractIdFromUnsignedTx;
// This function only works in the simple case where a single non-subcontract is created in the tx
exports.getTokenIdFromUnsignedTx = exports.getContractIdFromUnsignedTx;
async function getContractCodeByCodeHash(nodeProvider, codeHash) {
    if ((0, utils_1.isHexString)(codeHash) && codeHash.length === 64) {
        try {
            return await nodeProvider.contracts.getContractsCodehashCode(codeHash);
        }
        catch (error) {
            if (error instanceof Error && error.message.includes('not found')) {
                return undefined;
            }
            throw new error_1.TraceableError(`Failed to get contract by code hash ${codeHash}`, error);
        }
    }
    throw new Error(`Invalid code hash: ${codeHash}`);
}
exports.getContractCodeByCodeHash = getContractCodeByCodeHash;
